import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Funcoes from '../../Utils/functions';
import ApiService from '../../Utils/services';

export default class ProgrammedMessages extends Component {

    constructor() {
        super();

        this.acao = this.acao.bind(this);
        this.state = {
            redirect: false,
            path: '',
            propsToPath: '',
            loading: false,
            alert: false,
            alertMessage: '',
            table: {
                campos: [
                    { titulo: 'Campanha de Envio', dado: 'campanha' },
                    { titulo: 'Programação', dado: 'programacao', thStyle: { textAlign: 'center' }, tdStyle: { textAlign: 'center' } },
                    { titulo: 'Próx. Envio', dado: 'proxEnvio', thStyle: { textAlign: 'center' }, tdStyle: { textAlign: 'center' } },
                    { titulo: 'Status', dado: 'status', thStyle: { textAlign: 'center' }, tdStyle: { textAlign: 'center' } },
                    { titulo: '', dado: 'acoes' }
                ],
                dados: []
            },
            exibeDados: false,
            mostraVazio: false
        };
    }

    componentDidMount() {
        this.carregaMensagens();
    }

    async carregaMensagens() {
        const { table } = this.state;
        this.setState({ loading: true });
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        await ApiService.getEmpresa('mensageria/programacao', userData.token)
            .then(result => {
                const res = result.data.data, arrProg = ['', 'Imediato', 'Programado', 'Recorrente'], arrStatus = ['Inativo', 'Ativo', 'Finalizada'];
                var objects = [], i;

                for (i = 0; i < res.length; i++) {
                    const item = res[i];
                    const splitHours = item.msgprog_horaenvio.split(':');
                    objects.push({
                        id: item.msgprog_id, campanha: item.msgprog_titulo, programacao: arrProg[item.msgprog_tipoenvio],
                        proxEnvio: Funcoes.data2Brazilian(item.msgprog_dtproxenvio) + " " + splitHours[0] + ":" + splitHours[1], status: arrStatus[item.msgprog_status], acoes: 'editar'
                    })
                }
                this.setState({
                    table: { campos: [...table.campos], dados: objects }, exibeDados: res.length > 0 ? true : false,
                    mostraVazio: res.length > 0 ? false : true, loading: false
                });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertMessage: valida.response, alert: true });
            })
    }

    async acao(id) {
        this.setState({ loading: true });
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        await ApiService.getEmpresa('mensageria/programacao/' + id, userData.token)
            .then(result => {
                const res = result.data;
                this.setState({ propsToPath: { mensagem: res.data }, path: '/mensageria/editar-mensagem', loading: false, redirect: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertMessage: valida.response, alert: true });
            })
    }

    render() {
        const { redirect, path, propsToPath, loading, alert, alertMessage, table, exibeDados, mostraVazio } = this.state;
        return (
            <Fragment>
                <Alert show={alert} texto={alertMessage} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <Redirect redirect={redirect} path={path} props={propsToPath} />
                <SideMenu menuAtivo={8} />

                <div className="body">
                    <Header />

                    <div className="messaging">
                        <div className="tituloButton">
                            <div>
                                <h1>Programação de Mensagens</h1>
                                <p className="descricao">Abaixo listamos todos os templates configurados</p>
                            </div>
                            <button className="btn" onClick={() => this.setState({ path: '/mensageria/nova-mensagem', redirect: true })}>Incluir Novo</button>
                        </div>

                        <div className="panel">
                            {exibeDados ? (<>
                                <Table campos={table.campos}
                                    dados={table.dados}
                                    acao={this.acao} />
                            </>) : null}
                            {mostraVazio ? (
                                <p style={{ color: '#ABABAB', fontSize: 20 }}>Você ainda não tem mensagens programadas!</p>
                            ) : null}
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}