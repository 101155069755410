import React, { Component, Fragment } from 'react';

export default class Footer extends Component {

    render() {
        return (
            <Fragment>
                <div className="footer">
                    <p>Copyright &copy; 2020 Exoss Soluções de Pagamentos e Internet. Todos direitos reservados</p>
                </div>
            </Fragment>
        )
    }
}