
export default class Funcoes {

    static float2Preco(valor) {
        if (valor || valor === 0)
            return valor.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    }

    static preco2float(valor, suffix) {
        let valDots = valor.replace(".", "");
        let val = suffix ? valDots.replace(',', '.').split(suffix)[1] : valDots.replace(',', '.');
        return parseFloat(val);
    }

    static data2Americano(data, separador) {
        let dataSeparate = data.split(separador ? separador : '/');
        return dataSeparate[2] + "-" + dataSeparate[1] + "-" + dataSeparate[0];
    }

    static data2Brazilian(data, separador) {
        if (data) {
            let dataSeparate = data.split(separador ? separador : '-');
            return dataSeparate[2] + "/" + dataSeparate[1] + "/" + dataSeparate[0];
        }
    }

    static removeSegundos(horario) {
        if (horario) {
            let horarioSplit = horario.split(":");
            return horarioSplit[0] + ":" + horarioSplit[1];
        }
    }

    static primeiroNome(nome) {
        return nome.split(' ')[0];
    }

    static leftPad(value, length, preffix) {
        var pad = "";
        if (value) {
            for (var i = 0; i < length - value.toString().length; i++) {
                pad = pad + preffix;
            }
        }
        return pad + value;
    }

    static validaEmail(email) {
        return /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/.test(email);
    }

    static completarNumero(num) {
        if (num)
            return num.length < 10 ? '0' + num : num
    }

    static geraDataExtenso(data) {
        var dateSeparate, date;
        // const weekDay = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
        const month = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];

        if (data) {
            dateSeparate = data.split('-');
            date = new Date(data);
        } else {
            date = new Date();
            const toJSON = date.toJSON().split('T')[0];
            dateSeparate = toJSON.split('-');
        }
        const diaMes = parseInt(dateSeparate[1]);
        //01 de Junho de 2020
        return (`${dateSeparate[2]} de ${month[diaMes - 1]} de ${dateSeparate[0]}`);
    }

    static nFormatter(num, digits) {
        var si = [
            { value: 1, symbol: "" },
            { value: 1E3, symbol: "k" },
            { value: 1E6, symbol: "M" },
            { value: 1E9, symbol: "G" },
            { value: 1E12, symbol: "T" },
            { value: 1E15, symbol: "P" },
            { value: 1E18, symbol: "E" }
        ];
        var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var i;
        for (i = si.length - 1; i > 0; i--) {
            if (num >= si[i].value) {
                break;
            }
        }
        return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
    }

    static getAllUrlParams(url) {

        var queryString = url ? url.split('?')[1] : window.location.search.slice(1);
        var obj = {};

        if (queryString) {
            queryString = queryString.split('#')[0];

            var arr = queryString.split('&');

            for (var i = 0; i < arr.length; i++) {
                var a = arr[i].split('=');
                var paramName = a[0];
                var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

                paramName = paramName.toLowerCase();
                if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

                if (paramName.match(/\[(\d+)?\]$/)) {

                    var key = paramName.replace(/\[(\d+)?\]/, '');
                    if (!obj[key]) obj[key] = [];

                    if (paramName.match(/\[\d+\]$/)) {
                        var index = /\[(\d+)\]/.exec(paramName)[1];
                        obj[key][index] = paramValue;
                    } else {
                        obj[key].push(paramValue);
                    }
                } else {
                    if (!obj[paramName]) {
                        obj[paramName] = paramValue;
                    } else if (obj[paramName] && typeof obj[paramName] === 'string') {
                        obj[paramName] = [obj[paramName]];
                        obj[paramName].push(paramValue);
                    } else {
                        obj[paramName].push(paramValue);
                    }
                }
            }
        }
        return obj;
    }
}