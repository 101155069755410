import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Input from '../../Components/Input';
import Funcoes from '../../Utils/functions';
import { dataBrMask, horaMask } from '../../Utils/mask';
import { validaForm } from '../../Utils/FormValidator';
import ApiService from '../../Utils/services';

export default class NewMessage extends Component {

    constructor() {
        super();

        this.state = {
            redirect: false,
            path: '',
            loading: false,
            alert: false,
            alertMessage: '',
            alertType: 2,
            templatesContents: [],
            arrTemplates: [{ text: 'Selecione...', value: '' }],
            arrHorarios: [{ text: 'Selecione...', value: '' }],
            arrTipoEnvio: [
                { text: 'Selecione...', value: '' },
                { text: 'Imediato', value: 1 },
                { text: 'Programado', value: 2 },
                { text: 'Recorrente', value: 3 }
            ],
            arrCanal: [
                { text: 'Selecione...', value: '' },
                { text: 'SMS', value: 1 },
                { text: 'WhatsApp', value: 2 },
                { text: 'E-mail', value: 3 }
            ],
            arrRecorrencia: [
                { text: 'Selecione...', value: '' },
                { text: 'Diária', value: 1 },
                { text: 'Semanal', value: 2 },
                { text: 'Quinzenal', value: 3 },
                { text: 'Mensal', value: 4 }
            ],
            arrStatus: [
                { text: 'Selecione...', value: '' },
                { text: 'Inativo', value: 0 },
                { text: 'Ativo', value: 1 },
                { text: 'Finalizada', value: 2 }
            ],
            campanha: '',
            template: '',
            mensagem: '',
            isTextEditor: false,
            progEnvio: '',
            canalEnvio: '',
            dtEnvio: '',
            hrEnvio: '',
            recorrencia: '',
            status: '',
            diasVencidosIni: '',
            diasVencidosFim: '',
            diasVencerIni: '',
            diasVencerFim: '',
            valDebitoIni: '0,00',
            valDebitoFim: '0,00'
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        const userData = JSON.parse(sessionStorage.getItem('userData'));

        const date = new Date().toJSON().split('T')[0].split('-');
        var dt = new Date(date[0], date[1], date[2]), minutes = 0, horarios = [{ text: 'Selecione...', value: '' }];
        for (var i = 0; i < 96; i++) {
            dt.setMinutes(dt.getMinutes() + minutes);
            horarios.push({ text: Funcoes.removeSegundos(dt.toLocaleString().split(' ')[1]), value: Funcoes.removeSegundos(dt.toLocaleString().split(' ')[1]) });
            if (i === 0)
                minutes += 15;
        }
        this.setState({ arrHorarios: horarios });
        this.carregaTemplates(userData);
    }

    async carregaTemplates(userData) {
        await ApiService.getEmpresa('mensageria/template', userData.token)
            .then(result => {
                const res = result.data.data;
                var objects = [{ text: 'Selecione...', value: '' }], i;

                for (i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects.push({ value: item.msgtemp_id, text: item.msgtemp_nome });
                }
                this.setState({ templatesContents: res, arrTemplates: objects });
                if (this.props.location.state) {
                    const { mensagem } = this.props.location.state;
                    this.setState({
                        campanha: mensagem.msgprog_titulo, mensagem: mensagem.msgprog_mensagem, progEnvio: mensagem.msgprog_tipoenvio, canalEnvio: mensagem.msgprog_canalenvio,
                        dtEnvio: Funcoes.data2Brazilian(mensagem.msgprog_dtproxenvio), hrEnvio: Funcoes.removeSegundos(mensagem.msgprog_horaenvio),
                        recorrencia: mensagem.msgprog_tiporecorrencia, status: mensagem.msgprog_status, diasVencidosIni: mensagem.msgprog_diasvencidoinicio,
                        diasVencidosFim: mensagem.msgprog_diasvencidofim, diasVencerIni: mensagem.msgprog_diasvencerinicio, diasVencerFim: mensagem.msgprog_diasvencerfim,
                        valDebitoIni: mensagem.msgprog_cobvalorinicio ? Funcoes.float2Preco(mensagem.msgprog_cobvalorinicio) : '',
                        valDebitoFim: mensagem.msgprog_cobvalorfim ? Funcoes.float2Preco(mensagem.msgprog_cobvalorfim) : '', loading: false
                    })
                } else {
                    this.setState({ loading: false });
                }
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertMessage: valida.response, alert: true });
            })

    }

    handleForm = (attr, value, type) => {
        this.setState({ [attr]: type === "money" ? value : type === "data" ? dataBrMask(value.target.value) : type === "hora" ? horaMask(value.target.value) : value.target.value });
    }

    renderConteudoMensagem = (idTemplate) => {
        const { templatesContents } = this.state;
        var templateSelecionado = {};
        for (var i = 0; i < templatesContents.length; i++) {
            const item = templatesContents[i];
            if (item.msgtemp_id === parseInt(idTemplate))
                templateSelecionado = item;
        }
        if (templateSelecionado)
            this.setState({ isTextEditor: templateSelecionado.msgtemp_tipo === 3 ? true : false, canalEnvio: templateSelecionado.msgtemp_tipo, mensagem: templateSelecionado.msgtemp_conteudo });
    }

    validaCampos() {
        const { campanha, mensagem, progEnvio, canalEnvio, dtEnvio, hrEnvio, recorrencia, status, diasVencidosIni, diasVencidosFim, diasVencerIni, diasVencerFim,
            valDebitoIni, valDebitoFim } = this.state;
        const form = [
            { campo: "Nome da Campanha", valor: campanha },
            { campo: "Progamação Envio", valor: progEnvio },
            { campo: "Canal Envio", valor: canalEnvio },
            { campo: "Status", valor: status }
        ];
        if (progEnvio === "3") {
            form.push({ campo: "Data Envio", valor: dtEnvio, minLength: 10 });
            form.push({ campo: "Hora Envio", valor: hrEnvio, minLength: 5 });
            form.push({ campo: "Recorrência", valor: recorrencia });
        } else if (progEnvio === "2") {
            form.push({ campo: "Data Envio", valor: dtEnvio, minLength: 10 });
            form.push({ campo: "Hora Envio", valor: hrEnvio, minLength: 5 });
        }
        const valida = validaForm(form);
        if (valida.valido) {
            this.setState({ loading: true });
            var date = new Date();
            const formData = {
                msgprog_titulo: campanha,
                msgprog_diasvencidoinicio: diasVencidosIni,
                msgprog_diasvencidofim: diasVencidosFim,
                msgprog_diasvencerinicio: diasVencerIni,
                msgprog_diasvencerfim: diasVencerFim,
                msgprog_cobvalorinicio: valDebitoIni ? Funcoes.preco2float(valDebitoIni) : '',
                msgprog_cobvalorfim: valDebitoFim ? Funcoes.preco2float(valDebitoFim) : '',
                msgprog_tipoenvio: progEnvio,
                msgprog_canalenvio: canalEnvio,
                msgprog_tiporecorrencia: recorrencia,
                msgprog_dtproxenvio: progEnvio === "1" ? date.toISOString().split('T')[0] : Funcoes.data2Americano(dtEnvio),
                msgprog_horaenvio: progEnvio === "1" ? date.toISOString().split('T')[1].split('.')[0] : hrEnvio,
                msgprog_status: status,
                msgprog_mensagem: mensagem
            };
            const userData = JSON.parse(sessionStorage.getItem('userData'));
            if (this.props.location.state) {
                this.editMessage(formData, userData);
            } else {
                this.newMessage(formData, userData);
            }
        } else {
            for (var i = 0; i < valida.campos.length; i++) {
                const item = valida.campos[i];
                if (!item.valido) {
                    this.setState({ alertMessage: item.mensagem, alertType: 2, alert: true });
                    break;
                };
            }
        }
    }

    async newMessage(form, userData) {
        await ApiService.postEmpresa('mensageria/programacao', form, userData.token)
            .then(res => {
                this.setState({ alertMessage: 'Mensagem cadastrada com sucesso!', alertType: 1, loading: false, alert: true })
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ alertMessage: valida.response, alertType: 2, loading: false, alert: true });
            })
    }

    async editMessage(form, userData) {
        const { mensagem } = this.props.location.state;
        await ApiService.putEmpresa('mensageria/programacao/' + mensagem.msgprog_id, form, userData.token)
            .then(res => {
                this.setState({ alertMessage: 'Mensagem alterada com sucesso!', alertType: 1, loading: false, alert: true })
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ alertMessage: valida.response, alertType: 2, loading: false, alert: true });
            })
    }

    render() {
        const { redirect, path, loading, alert, alertMessage, alertType, arrTemplates, arrHorarios, arrTipoEnvio, arrCanal, arrRecorrencia, arrStatus, campanha, template, mensagem,
            isTextEditor, progEnvio, canalEnvio, dtEnvio, hrEnvio, recorrencia, status, diasVencidosIni, diasVencidosFim, diasVencerIni, diasVencerFim,
            valDebitoIni, valDebitoFim } = this.state;
        return (
            <Fragment>
                <Alert show={alert} texto={alertMessage} tipo={alertType} action={() => {
                    this.setState({ alert: false, redirect: path ? true : false });
                    if (alertType === 1)
                        this.setState({ path: '/mensageria/programacao-mensagens', redirect: true });
                }} />
                <Loading show={loading} />
                <Redirect redirect={redirect} path={path} />
                <SideMenu menuAtivo={8} />

                <div className="body">
                    <Header />

                    <div className="messaging">
                        <div className="tituloButton">
                            <div>
                                <h1>{this.props.location.state ? 'Editar' : 'Nova'} Mensagem</h1>
                                <p className="descricao">Preencha os campos abaixo para configurar um {this.props.location.state ? '' : 'novo'} template de mensagem.</p>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados da Mensagem</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multiInput" style={{ paddingBottom: 0 }}>
                                    <Input label="Nome da Campanha *" type="text" value={campanha} onChange={e => this.handleForm('campanha', e)} />
                                    <Input label="Template de Mensagem" type="select" optionsData={arrTemplates} value={template} onChange={e => {
                                        this.handleForm('template', e);
                                        this.renderConteudoMensagem(e.target.value);
                                        if (!e.target.value) this.setState({ canalEnvio: '' });
                                    }} />
                                </div>
                                <Input label="Mensagem" type="textarea" row={9} cols={33} value={mensagem} onChange={e => this.handleForm('mensagem', e)} textEditor={isTextEditor} />
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Programação de Envio</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multiInput selectsPadding" style={{ paddingBottom: 0 }}>
                                    <Input label="Progamação Envio *" type="select" optionsData={arrTipoEnvio} value={progEnvio} onChange={e => this.handleForm('progEnvio', e)} />
                                    {progEnvio === '1' || progEnvio === '2' || progEnvio === '3' ? (
                                        <Input label="Canal Envio *" type="select" optionsData={arrCanal} value={canalEnvio} onChange={e => this.handleForm('canalEnvio', e)} />
                                    ) : null}
                                    {progEnvio === '2' || progEnvio === '3' ? (
                                        <Input label="Data Envio *" type="tel" value={dtEnvio} onChange={e => this.handleForm('dtEnvio', e, 'data')} maxLength={10} />
                                    ) : null}
                                </div>
                                <div className="multiInput selectsPadding" style={{ paddingBottom: 0 }}>
                                    {progEnvio === '2' || progEnvio === '3' ? (
                                        <Input label="Hora Envio *" type="select" optionsData={arrHorarios} value={hrEnvio} onChange={e => this.handleForm('hrEnvio', e)} />
                                    ) : null}
                                    {progEnvio === '3' ? (
                                        <Input label="Recorrência *" type="select" optionsData={arrRecorrencia} value={recorrencia} onChange={e => this.handleForm('recorrencia', e)} />
                                    ) : null}
                                    {progEnvio === '1' || progEnvio === '2' || progEnvio === '3' ? (
                                        <Input label="Status *" type="select" optionsData={arrStatus} value={status} onChange={e => this.handleForm('status', e)} style={{ marginRight: 0 }} />
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Definição de Público Alvo</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multiInput" style={{ paddingBottom: 0 }}>
                                    <Input label="Dias vencidos" type="tel" value={diasVencidosIni} onChange={e => this.handleForm('diasVencidosIni', e)} maxLength={2}
                                        divStyle={{ maxWidth: 150 }} />
                                    <Input label="a" type="tel" value={diasVencidosFim} onChange={e => this.handleForm('diasVencidosFim', e)} maxLength={2} divStyle={{ maxWidth: 150 }} />
                                    <Input label="Dias à vencer" type="tel" value={diasVencerIni} onChange={e => this.handleForm('diasVencerIni', e)} maxLength={2}
                                        divStyle={{ maxWidth: 150 }} />
                                    <Input label="a" type="tel" value={diasVencerFim} onChange={e => this.handleForm('diasVencerFim', e)} maxLength={2} divStyle={{ maxWidth: 150 }} />
                                    <Input label="Valor do débito" type="money" value={valDebitoIni} onChangeEvent={(e, value) => this.handleForm('valDebitoIni', value, 'money')}
                                        divStyle={{ maxWidth: 190 }} />
                                    <Input label="a" type="money" value={valDebitoFim} onChangeEvent={(e, value) => this.handleForm('valDebitoFim', value, 'money')} divStyle={{ maxWidth: 190 }} />
                                </div>
                            </div>
                        </div>

                        <div className="actions">
                            <Link to="/mensageria/programacao-mensagens" className="btn">{'<'} Voltar</Link>
                            <button className="btn" onClick={() => this.validaCampos()}>{this.props.location.state ? 'Alterar' : 'Salvar'}</button>
                        </div>

                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}