import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import ApiService from '../../Utils/services';
import Funcoes from '../../Utils/functions';

export default class Debit extends Component {

    constructor() {
        super();

        this.state = {
            redirect: false,
            path: '',
            loading: false,
            alert: false,
            alertText: '',
            table: {
                campos: [
                    { titulo: 'Mês/Ano', dado: 'mesAno', thStyle: { textAlign: 'center', width: 150 }, tdStyle: { textAlign: 'center' } },
                    { titulo: 'Faturas', dado: 'faturas', thStyle: { width: 1 }, tdStyle: { textAlign: 'center' } },
                    { titulo: 'Total de Débitos', dado: 'totalDebitos', thStyle: { textAlign: 'right' }, tdStyle: { textAlign: 'right' } },
                    { titulo: 'Total Recebido', dado: 'totalRecebido', thStyle: { textAlign: 'right' }, tdStyle: { textAlign: 'right' } }
                ],
                dados: []
            },
            exibeDados: false,
            mostraVazio: false,
            totalDebitos: 0,
            totalRecebido: 0
        };
    }

    async componentDidMount() {
        const { table } = this.state;
        this.setState({ loading: true });
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        ApiService.getEmpresa('debito', userData.token)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                var totalDebitos = 0, totalRecebido = 0;
                for (var i = 0; i < res.length; i++) {
                    const data = res[i].mes_ano.split('/');
                    const mesAno = data[0] < 10 ? "0" + data[0] + "/" + data[1] : data[0] + "/" + data[1];
                    objects[i] = {
                        mesAno, faturas: res[i].faturas, totalDebitos: 'R$ ' + Funcoes.float2Preco(res[i].total_debitos),
                        totalRecebido: res[i].total_recebido ? 'R$ ' + Funcoes.float2Preco(res[i].total_recebido) : 'R$ 0,00'
                    }
                    totalDebitos += res[i].total_debitos;
                    totalRecebido += res[i].total_recebido;
                }
                objects.push({
                    mesAno: 'Totais:', faturas: '', totalDebitos: 'R$ ' + Funcoes.float2Preco(totalDebitos),
                    totalRecebido: 'R$ ' + Funcoes.float2Preco(totalRecebido)
                });
                this.setState({
                    table: { campos: [...table.campos], dados: objects }, clientesDefault: result, exibeDados: res.length > 0 ? true : false,
                    mostraVazio: res.length > 0 ? false : true, totalDebitos, totalRecebido, loading: false
                });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    render() {
        const { redirect, path, loading, alert, alertText, table, exibeDados, mostraVazio } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} path={path} />
                <Loading show={loading} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <SideMenu menuAtivo={3} />

                <div className="body">
                    <Header />

                    <div className="debitos">
                        <div className="tituloButton">
                            <div>
                                <h1>Débitos</h1>
                                <p className="descricao">Abaixo listamos todos os débitos de clientes importado para a plataforma.</p>
                            </div>
                            <button className="btn" onClick={() => this.setState({ path: '/debitos/importar-baixas', redirect: true })}
                                style={{ borderColor: '#ABABAB', color: '#ABABAB' }}>Baixar Débitos</button>
                            <button className="btn" onClick={() => this.setState({ path: '/debitos/importar', redirect: true })}
                                style={{ marginLeft: 20 }}>Importar Arquivo</button>
                        </div>

                        <div className="panel">
                            {exibeDados > 0 ? (<>
                                <Table campos={table.campos}
                                    dados={table.dados} />
                            </>) : null}
                            {mostraVazio ? (
                                <p style={{ color: '#ABABAB', fontSize: 20 }}>Você ainda não tem débitos!</p>
                            ) : null}
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}