import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Input from '../../Components/Input';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import ApiService from '../../Utils/services';

export default class ChangePassword extends Component {

    constructor() {
        super();

        this.state = {
            redirect: false,
            path: '',
            loading: false,
            alert: false,
            alertMessage: '',
            alertType: 0,
            senhaAtual: '',
            senha: '',
            cSenha: ''
        };
    }

    async validaCampos() {
        const { senhaAtual, senha, cSenha } = this.state;
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        if (senhaAtual === userData.usuario.senha) {
            if (senha && (cSenha === senha)) {
                this.setState({ loading: true });
                await ApiService.postEmpresa('alterarsenha', { eusu_senha: senha }, userData.token)
                    .then(res => {
                        const user = userData.usuario;
                        const newData = { ...userData, usuario: { id: user.id, nome: user.nome, senha, email: user.email, status: user.status } };
                        sessionStorage.setItem('userData', JSON.stringify(newData));
                        this.setState({
                            alertMessage: 'Senha alterada com sucesso!', alertType: 1, loading: false, alert: true, senhaAtual: '', 
                            senha: '', cSenha: ''
                        });
                    })
                    .catch(err => {
                        const valida = ApiService.handleErros(err.response);
                        if (valida.status === "unauthorized") {
                            sessionStorage.removeItem('userData');
                            this.setState({ path: '/' });
                        }
                        if (valida.status === "serverError") { this.setState({ path: '' }); }
                        if (valida.status === "badRequest") { this.setState({ path: '' }); }
                        this.setState({ loading: false, alertMessage: valida.response, alertType: 0, alert: true });
                    })
            } else {
                this.setState({ alertMessage: 'A novas senhas não conferem!', alertType: 0, alert: true });
            }
        } else {
            this.setState({ alertMessage: 'A senha atual digitada está incorreta!', alertType: 0, alert: true });
        }
    }

    render() {
        const { redirect, path, loading, alert, alertMessage, alertType, senhaAtual, senha, cSenha } = this.state;
        return (
            <Fragment>
                <Alert show={alert} texto={alertMessage} tipo={alertType}
                    action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <Redirect redirect={redirect} path={path} />
                <SideMenu menuAtivo={6} />

                <div className="body">
                    <Header />

                    <div className="configuracao">
                        <h1>Alterar senha</h1>
                        <p className="descricao">Preencha os campos abaixo para alterar a senha da sua conta.</p>

                        <div className="panel senhas">
                            <div className="panelBody">
                                <Input type="password" label="Senha Atual" style={{ width: 350 }} value={senhaAtual}
                                    onChange={e => this.setState({ senhaAtual: e.target.value })} />

                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 60 }}>
                                    <Input type="password" label="Nova Senha" style={{ width: 350, marginRight: 30 }} grow={0} value={senha}
                                        onChange={e => this.setState({ senha: e.target.value })} />
                                    <Input type="password" label="Confirma Nova Senha" style={{ width: 350 }} grow={0} value={cSenha}
                                        onChange={e => this.setState({ cSenha: e.target.value })} />
                                </div>
                            </div>
                        </div>
                        <div className="actions">
                            <button className="btn bg-roxo cl-branco" onClick={() => this.validaCampos()}>Alterar senha</button>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}