import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Input from '../../Components/Input';
import Table from '../../Components/Table';
import Paginacao from '../../Components/Paginacao';
import Modal from '../../Components/Modal';
import Funcoes from '../../Utils/functions';
import { cpfMask } from '../../Utils/mask';
import ApiService from '../../Utils/services';

export default class Client extends Component {

    constructor() {
        super();

        this.handlePage = this.handlePage.bind(this);
        this.acaoEditar = this.acaoEditar.bind(this);
        this.state = {
            redirect: false,
            path: '',
            loading: false,
            alert: false,
            alertText: '',
            modal: false,
            ativacoes: [
                { value: '', text: 'Selecione' },
            ],
            situacoes: [
                { value: '', text: 'Selecione' },
                { value: 1, text: 'Em Débito' },
                { value: 2, text: 'Pago' },
                { value: 3, text: 'Pg Parcial' }
            ],
            filtroOptions: [
                { value: 'nome', text: 'Nome' },
                { value: 'cpf', text: 'CPF' }
            ],
            table: {
                campos: [
                    { titulo: 'Cliente', dado: 'cliente' },
                    { titulo: 'Documento', dado: 'documento' },
                    { titulo: 'Data Ativação', dado: 'dataAtivacao' },
                    { titulo: 'Situação', dado: 'tags' },
                    { titulo: 'Ações', dado: 'acoes' }
                ],
                dados: []
            },
            clientesDefault: [],
            exibeDados: false,
            mostraVazio: false,
            ativacao: '',
            situacao: '',
            valorFiltro: 'nome',
            filtro: '',
            cliData: {}
        };
    }

    async componentDidMount() {
        var meses = [];
        meses[0] = { value: '', text: 'Selecione' };
        for (var i = 1; 5 >= i; i++) {
            const fullData = new Date(new Date().setMonth(new Date().getMonth() - (i - 1)));
            const separaDatas = fullData.toJSON().split('T')[0].split('-');
            meses[i] = { value: separaDatas[0] + "-" + separaDatas[1], text: separaDatas[1] + "/" + separaDatas[0] };
        }
        this.setState({ ativacoes: meses })
        this.listaClientes(0);
    }

    async listaClientes(usaFiltro, page) {
        const { table, ativacao, situacao, valorFiltro, filtro } = this.state;
        this.setState({ loading: true });

        var url, pagina, pagAtual;
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        pagina = Funcoes.getAllUrlParams(window.location.href);
        pagina = pagina.page ? pagina.page : 1;
        pagAtual = page ? page : pagina;

        if (pagAtual) {
            url = "cliente?per_page=20&page=" + pagAtual;
        } else {
            url = "cliente?per_page=20";
        }
        if (usaFiltro && ativacao)
            url = url + "&cli_dthrcadastro=" + ativacao;

        if (usaFiltro && situacao)
            url = url + "&status=" + situacao;

        if (usaFiltro && valorFiltro && filtro) {
            if (valorFiltro === "nome") {
                url = url + "&cli_nome=" + filtro;
            } else {
                url = url + "&cli_cpf=" + filtro;
            }
        }

        await ApiService.getEmpresa(url, userData.token)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    const arrTag = ['Em débito', 'Pg Parcial', 'Em dia (À vencer)', 'Sem débitos', 'Pago'];
                    //0- Debito, 1- Pgto Parcial, 2- Em dia, 3- Sem Cobrancas, 4- Pago
                    objects[i] = { id: item.cli_id, cliente: item.cli_nome, documento: item.cli_cpf, tags: arrTag[item.status],
                        dataAtivacao: Funcoes.data2Brazilian(item.cli_dthrcadastro.split(' ')[0], '-') + ' ' + item.cli_dthrcadastro.split(' ')[1],
                        acoes: 'visualizar'
                    }
                }
                this.setState({
                    table: { campos: [...table.campos], dados: objects }, clientesDefault: result.data, exibeDados: res.length > 0 ? true : false,
                    mostraVazio: res.length > 0 ? false : true, loading: false
                });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async acaoEditar(id) {
        this.setState({ loading: true });
        const userData = JSON.parse(sessionStorage.getItem('userData'));

        await ApiService.getEmpresa('cliente/' + id, userData.token)
            .then(res => {
                this.setState({ cliData: res.data.data, loading: false, modal: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    handlePage(page) {
        if (page) {
            this.setState({ path: '/clientes?page=' + page, redirect: true });
        } else {
            this.setState({ path: '/clientes', redirect: true });
        }
        this.listaClientes(0, page);
    }

    render() {
        const { redirect, path, loading, alert, alertText, modal, ativacoes, table, clientesDefault, exibeDados, mostraVazio, ativacao, filtroOptions, valorFiltro, filtro,
            cliData } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} path={path} />
                <Loading show={loading} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Modal show={modal} largura={520}>
                    <div className="cliModal">
                        <button style={{ float: 'right', fontSize: 20 }} onClick={() => this.setState({ modal: false })}>X</button>
                        <h2 style={{ clear: 'both' }}>Dados do Cliente:</h2>
                        <div style={{ textAlign: 'left', marginTop: 30 }}>
                            <h3>Dados Cadastrais:</h3>
                            <div style={{ marginLeft: 10 }}>
                                <p><b>Nome:</b> {cliData.cli_nome}</p>
                                <p><b>CPF:</b> {cliData.cli_cpf}</p>
                                <p><b>Data de Nascimento:</b> {Funcoes.data2Brazilian(cliData.cli_dtnascimento)}</p>
                                <p><b>Email:</b> {cliData.cli_email}</p>
                                <p style={{ marginBottom: 40 }}><b>Celular:</b> {cliData.cli_celular}</p>
                            </div>

                            <h3>Dados de Endereço:</h3>
                            <div style={{ marginLeft: 10 }}>
                                <p><b>CEP:</b> {cliData.cli_endcep}</p>
                                <p><b>Endereco:</b> {cliData.cli_endlogradouro}</p>
                                <p><b>Número:</b> {cliData.cli_endnumero}</p>
                                <p><b>Bairro:</b> {cliData.cli_endbairro}</p>
                                <p><b>Complemento:</b> {cliData.cli_complemento}</p>
                            </div>
                        </div>
                    </div>
                </Modal>
                <SideMenu menuAtivo={2} />

                <div className="body">
                    <Header />

                    <div className="clientes">
                        <h1>Clientes</h1>
                        <p className="descricao">Abaixo listamos todos o cliente que ativaram a conta na plataforma.</p>

                        <div className="filtros">
                            <div className="panel">
                                <Input type="select" label="Mês Ativação" optionsData={ativacoes} grow={0} value={ativacao}
                                    onChange={e => this.setState({ ativacao: e.target.value })} />
                                <Input type="select" label="Filtrar por" optionsData={filtroOptions} grow={0} value={valorFiltro}
                                    onChange={e => this.setState({ valorFiltro: e.target.value, filtro: '' })} />
                                <Input type="text" label="a" grow={0} value={filtro}
                                    onChange={e => this.setState({ filtro: valorFiltro === "cpf" ? cpfMask(e.target.value) : e.target.value })} />
                                <button className="btn bg-roxo cl-branco" onClick={() => this.listaClientes(1)}>Filtrar</button>
                            </div>
                        </div>

                        <div className="detalhes">
                            <div className="panel">
                                {exibeDados ? (
                                    <Table campos={table.campos}
                                        dados={table.dados}
                                        acao={this.acaoEditar} />
                                ) : null}
                                {mostraVazio ? (
                                    <p style={{ color: '#ABABAB', fontSize: 20 }}>{ativacao ? 'Nenhum cliente realizou ativação na plataforma no período!' : 'Nenhum cliente encontrado.'}</p>
                                ) : null}
                            </div>
                            {exibeDados > 0 ? (
                                <div className="info">
                                    <p>Total de registros: {clientesDefault.total}</p>
                                    <Paginacao dados={clientesDefault}
                                        handlePage={this.handlePage} />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}