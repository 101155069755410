import React, { Component, Fragment } from 'react';

import iconDashboard from '../resources/icon-dashboard.svg';
import iconClientes from '../resources/icon-clientes.svg';
import iconDebitos from '../resources/icon-debitos.svg';
import iconPagamentos from '../resources/icon-pagamentos.svg';
import iconNegociacao from '../resources/icon-negociacao.svg';
import iconMensageria from '../resources/icon-mensageria.svg';
import iconConfiguracao from '../resources/icon-configuracao.svg';
import iconExpandir from '../resources/icon-expandir.svg';
import iconHistorico from '../resources/icon-historico.svg';

import ApiService from '../Utils/services';

import { Link } from 'react-router-dom';

export default class SideMenu extends Component {

    constructor() {
        super();

        this.state = {
            empNome: '',
            empImg: '',
            sideMenus: []
        };
    }

    componentDidMount() {
        const session = sessionStorage.getItem('userData');
        if (session) {
            const userData = JSON.parse(session);
            var apiUrl = ApiService.TchauBoletoApiUrl();
            this.setState({
                empNome: userData.empresa.nomeFantasia,
                empImg: userData.empresa.arqId ? apiUrl + "/geral/arquivo/" + userData.empresa.arqId + "/ver" : 'http://via.placeholder.com/80x80'
            });
            var menusAtivos = [
                { url: "/home", menuAtivo: 1, icone: iconDashboard },
                { url: "/clientes", menuAtivo: 2, icone: iconClientes },
                { url: "/debitos", menuAtivo: 3, icone: iconDebitos },
                { url: "/pagamentos", menuAtivo: 4, icone: iconPagamentos },
                { url: "/negociacao", menuAtivo: 5, icone: iconNegociacao },
                { url: "/liquidacao-automatica", menuAtivo: 7, icone: iconNegociacao },
                { url: "/configuracoes", menuAtivo: 6, icone: iconConfiguracao },
                { url: "/alterar-senha", menuAtivo: 6, icone: iconConfiguracao },
                { url: "/perfil-acesso", menuAtivo: 6, icone: iconConfiguracao },
                { url: "/usuarios", menuAtivo: 6, icone: iconConfiguracao },
                { url: "/mensageria", menuAtivo: 8, icone: iconMensageria },
                { url: "/mensageria/configuracao-template", menuAtivo: 8, icone: iconMensageria },
                { url: "/credito/historico", menuAtivo: 9, icone: iconHistorico }
            ];
            var menus = [];
            for (var i = 0; i < userData.menus.length; i++) {
                const menu = userData.menus[i];
                menus[i] = { url: menu.pmnu_url, nome: menu.pmnu_nome, filhos: menu.filhos };
                for (var x = 0; x < menusAtivos.length; x++) {
                    if (menu.pmnu_url === menusAtivos[x].url) {
                        menus[i]['menuAtivo'] = menusAtivos[x].menuAtivo;
                        menus[i]['icone'] = menusAtivos[x].icone
                    }
                }
            }
            this.setState({ sideMenus: menus });
        }
    }

    handleDropdown = (idx) => {
        var dropdown = document.getElementsByClassName("dropdown-container");
        var btns = document.getElementsByClassName("dropdown-btn");
        var imgs = document.getElementsByClassName("iconExpandir");

        for (var x = 0; x < btns.length; x++) {
            if (btns[x].attributes.rel.value == idx) {
                if (btns[x].classList.contains('ativo')) {
                    var el = btns[x];
                    setTimeout(() => {
                        el.classList.remove('ativo');
                    }, 350);
                } else {
                    btns[x].classList.add('ativo');
                }
            }
        }

        for (var i = 0; i < dropdown.length; i++) {
            if (dropdown[i].attributes.rel.value == idx) {
                if (dropdown[i].style.display === "block") {
                    dropdown[i].style.height = "0";
                    dropdown[i].classList.remove('active');
                    var element = dropdown[i];
                    setTimeout(() => {
                        element.style.display = "none"
                    }, 350);
                } else {
                    dropdown[i].style.display = "block";

                    const height = dropdown[i].scrollHeight + "px";
                    dropdown[i].classList.add('active');
                    dropdown[i].style.height = height;
                }
            }
        }

        for (var y = 0; y < imgs.length; y++) {
            if (imgs[y].attributes.rel.value == idx) {
                if (imgs[y].classList.contains('ativo')) {
                    imgs[y].classList.remove('ativo');
                }else{
                    imgs[y].classList.add('ativo');
                }
            }
        }
    }

    render() {
        const { menuAtivo } = this.props;
        const { empImg, empNome, sideMenus } = this.state;
        return (
            <Fragment>
                <div className="sideMenuBar bg-roxo">
                    <div className="profile">
                        <img src={empImg} alt="Foto de perfil" style={{ width: 80 }} />
                        <p>{empNome}</p>
                    </div>
                    <div className="navigation">
                        <ul>
                            {sideMenus.map((item, idx) => {
                                if (item.filhos.length > 0) {
                                    var btnClass = "dropdown-btn";
                                    if (menuAtivo === item.menuAtivo) btnClass = btnClass + " active";
                                    return (
                                        <li key={idx}>
                                            <a className={btnClass} rel={idx} onClick={() => this.handleDropdown(idx)}>
                                                <img src={item.icone} alt={`Ícone ${item.nome}`} /> {item.nome}
                                                <img src={iconExpandir} className="iconExpandir" rel={idx} alt="Expandir menu" style={{ width: 15, marginLeft: 'auto' }} />
                                            </a>
                                            <div className="dropdown-container" rel={idx}>
                                                {item.filhos.map((child) => {
                                                    return (
                                                        <Link to={child.pmnu_url}>&raquo; &nbsp;{child.pmnu_nome}</Link>
                                                    )
                                                })}
                                            </div>
                                        </li>
                                    )
                                } else {
                                    return (
                                        <li key={idx}>
                                            <Link to={item.url} className={menuAtivo === item.menuAtivo ? "active" : ""}>
                                                <img src={item.icone} alt={`Ícone ${item.nome}`} /> {item.nome}
                                            </Link>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                    </div>
                </div>
            </Fragment>
        )
    }
}