import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Collapse } from 'react-collapse';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Input from '../../Components/Input';
import { validaForm } from '../../Utils/FormValidator';
import ApiService from '../../Utils/services';

export default class NewTemplate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            path: '',
            loading: false,
            alert: false,
            alertMessage: '',
            alertType: 2,
            openCollapse: false,
            arrTipo: [
                { text: 'Selecione...', value: '' },
                { text: 'SMS', value: '1' },
                { text: 'Whatsapp', value: '2' },
                { text: 'Email', value: '3' }
            ],
            arrStatus: [
                { text: 'Selecione...', value: '' },
                { text: 'Ativo', value: '1' },
                { text: 'Inativo', value: '2' },
                { text: 'Excluído', value: '3' }
            ],
            nome: '',
            tipo: '',
            status: '',
            mensagem: ''
        };
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({ loading: true });
            const { template } = this.props.location.state;
            this.setState({ nome: template.msgtemp_nome, tipo: template.msgtemp_tipo, status: template.msgtemp_status, mensagem: template.msgtemp_conteudo, loading: false })
        }
    }

    handleForm = (attr, value, isValueClean) => {
        this.setState({ [attr]: isValueClean ? value : value.target.value });
    }

    validaCampos() {
        const { nome, tipo, status, mensagem } = this.state;
        const form = [
            { campo: "Nome", valor: nome },
            { campo: "Tipo", valor: tipo },
            { campo: "Status", valor: status }
        ];
        const valida = validaForm(form);
        if (valida.valido) {
            this.setState({ loading: true });
            const formData = {
                msgtemp_nome: nome,
                msgtemp_tipo: tipo,
                msgtemp_conteudo: mensagem,
                msgtemp_status: status,
                msgtemp_predefinida: 0
            };
            const userData = JSON.parse(sessionStorage.getItem('userData'));
            if (this.props.location.state) {
                this.editTemplate(formData, userData);
            } else {
                this.newTemplate(formData, userData);
            }
        } else {
            for (var i = 0; i < valida.campos.length; i++) {
                const item = valida.campos[i];
                if (!item.valido) {
                    this.setState({ alertMessage: item.mensagem, alertType: 2, alert: true });
                    break;
                };
            }
        }
    }

    async newTemplate(form, userData) {
        await ApiService.postEmpresa('mensageria/template', form, userData.token)
            .then(res => {
                this.setState({ alertMessage: 'Template cadastrado com sucesso!', alertType: 1, loading: false, alert: true })
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ alertMessage: valida.response, alertType: 2, loading: false, alert: true });
            })
    }

    async editTemplate(form, userData) {
        const { template } = this.props.location.state;
        await ApiService.putEmpresa('mensageria/template/' + template.msgtemp_id, form, userData.token)
            .then(res => {
                this.setState({ alertMessage: 'Template alterado com sucesso!', alertType: 1, loading: false, alert: true })
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ alertMessage: valida.response, alertType: 2, loading: false, alert: true });
            })
    }

    render() {
        const { redirect, path, loading, alert, alertMessage, alertType, openCollapse, arrTipo, arrStatus, nome, tipo, status, mensagem } = this.state;
        return (
            <Fragment>
                <Alert show={alert} texto={alertMessage} tipo={alertType} action={() => {
                    this.setState({ alert: false, redirect: path ? true : false });
                    if (alertType === 1)
                        this.setState({ path: '/mensageria/configuracao-template', redirect: true });
                }} />
                <Loading show={loading} />
                <Redirect redirect={redirect} path={path} />
                <SideMenu menuAtivo={8} />

                <div className="body">
                    <Header />

                    <div className="messaging">
                        <div className="tituloButton">
                            <div>
                                <h1>{this.props.location.state ? 'Editar' : 'Novo'} Template</h1>
                                <p className="descricao">Preencha os campos abaixo para configurar um {this.props.location.state ? '' : 'novo'} template de mensagem.</p>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Informações</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multiInput" style={{ paddingBottom: 0 }}>
                                    <Input label="Nome *" type="text" value={nome} onChange={e => this.handleForm('nome', e)} />
                                    <Input label="Tipo *" type="select" optionsData={arrTipo} value={tipo} onChange={e => this.handleForm('tipo', e)} style={{ marginRight: 20 }} />
                                    <Input label="Status *" type="select" optionsData={arrStatus} value={status} onChange={e => this.handleForm('status', e)} />
                                </div>
                                <div className="expandable">
                                    <button className="btnExpandable" onClick={() => this.setState({ openCollapse: !openCollapse })}>Instruções: [+]</button>
                                    <Collapse isOpened={openCollapse}>
                                        <div style={{ marginTop: 4 }}>Utilize os campos abaixo entre chaves para personalizar sua mensagem. <br></br><br></br>
                                            {'{primeiro_nome}'} = Primeiro Nome <br></br>
                                            {'{nome_completo}'} = Nome Completo <br></br>
                                            {'{valor_cobranca}'} = Valor da Cobrança <br></br>
                                            {'{limite_parcelas}'} = Limite de parcelas sem juros <br></br>
                                            {'{tipo_juros}'} = Tipo do juros (Com juros, Sem juros) <br></br>
                                            {'{link_portal_parceiro}'} = Link do portal <br></br>
                                            {'{link_pagamento_cobranca}'} = Link para pagamento da cobrança <br></br>
                                            {'{dias_vencido}'} = Dias já vencidos da cobrança <br></br><br></br>
                                            Exemplo: Olá {'{primeiro_nome}'}! Pague seu débito vencido há {'{dias_vencido}'} em até 12x no cartão de crédito.
                                        </div>
                                    </Collapse>
                                </div>
                                <Input label="Mensagem" type="textarea" row={9} cols={33} textEditor={tipo === '3' || tipo === 3 ? true : false}
                                    value={mensagem} onChange={e => this.handleForm('mensagem', e)} changeEditor={this.handleForm} />
                            </div>
                        </div>

                        <div className="actions">
                            <Link to="/mensageria/configuracao-template" className="btn">{'<'} Voltar</Link>
                            <button className="btn" onClick={() => this.validaCampos()}>{this.props.location.state ? 'Alterar' : 'Salvar'}</button>
                        </div>

                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}