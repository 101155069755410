import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Input from '../../Components/Input';
import Funcoes from '../../Utils/functions';
import { dataBrMask, horaMask } from '../../Utils/mask';
import { validaForm } from '../../Utils/FormValidator';
import ApiService from '../../Utils/services';

import $ from "jquery";
import { priceFormat } from 'jquery-price-format';

export default class NewMessage extends Component {

    constructor() {
        super();

        this.state = {
            redirect: false,
            path: '',
            loading: false,
            alert: false,
            alertMessage: '',
            alertType: 2,
            templatesContents: [],
            arrFaixaDesconto: [{ text: 'Selecione...', value: '' }],
            faixa_desconto: '',
            porcentagem: ''
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        $('#porcentagem').priceFormat({
            prefix: '',
            centsSeparator: ',',
            thousandsSeparator: '.'
        });
        this.carregaFaixas(userData);
    }

    async carregaFaixas(userData) {
        await ApiService.getEmpresa('autonegociacao/faixa', userData.token)
            .then(result => {
                const res = result.data.data;
                var objects = [{ text: 'Selecione...', value: '' }], i;

                for (i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects.push({ value: item.faxdesc_id, text: item.faxdesc_nome });
                }
                this.setState({ arrFaixaDesconto: objects, loading: false });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertMessage: valida.response, alert: true });
            })

    }

    handleForm = (attr, value, type) => {
        this.setState({ [attr]: type === "money" ? value : type === "data" ? dataBrMask(value.target.value) : type === "hora" ? horaMask(value.target.value) : value.target.value });
    }

    validaCampos() {
        const { faixa_desconto } = this.state;
        var porcentagem = parseFloat($('#porcentagem').val().replace(',', '.'))
        const form = [
            { campo: "Faixa de Desconto", valor: faixa_desconto },
            { campo: "Porcentagem", valor: porcentagem }
        ];
        const valida = validaForm(form);
        if (valida.valido) {
            this.setState({ loading: true });
            var date = new Date();
            const formData = {
                faxdesc_id: faixa_desconto,
                fxdemp_porcentagem: porcentagem
            };
            const userData = JSON.parse(sessionStorage.getItem('userData'));
            this.newFaixa(formData, userData);
        } else {
            for (var i = 0; i < valida.campos.length; i++) {
                const item = valida.campos[i];
                if (!item.valido) {
                    this.setState({ alertMessage: item.mensagem, alertType: 2, alert: true });
                    break;
                };
            }
        }
    }

    async newFaixa(form, userData) {
        await ApiService.postEmpresa('autonegociacao/desconto', form, userData.token)
            .then(res => {
                this.setState({ alertMessage: 'Desconto cadastrado com sucesso!', alertType: 1, loading: false, alert: true })
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ alertMessage: valida.response, alertType: 2, loading: false, alert: true });
            })
    }

    render() {
        const { redirect, path, loading, alert, alertMessage, alertType, arrFaixaDesconto, faixa_desconto, porcentagem } = this.state;
        return (
            <Fragment>
                <Alert show={alert} texto={alertMessage} tipo={alertType} action={() => {
                    this.setState({ alert: false, redirect: path ? true : false });
                    if (alertType === 1)
                        this.setState({ path: '/autonegociacao', redirect: true });
                }} />
                <Loading show={loading} />
                <Redirect redirect={redirect} path={path} />
                <SideMenu menuAtivo={6} />

                <div className="body">
                    <Header />

                    <div className="messaging">
                        <div className="tituloButton">
                            <div>
                                <h1>Novo Desconto</h1>
                                <p className="descricao">Preencha os campos abaixo para configurar um novo desconto.</p>
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multiInput" style={{ paddingBottom: 0 }}>
                                    <Input label="Faixa de Desconto" type="select" optionsData={arrFaixaDesconto} value={faixa_desconto} onChange={e => this.setState({ faixa_desconto: e.target.value })} style={{ marginRight: 20 }} />
                                    <Input type="tel" label="Porcentagem" id="porcentagem" style={{ marginRight: 0 }} />
                                </div>
                            </div>
                        </div>

                        <div className="actions">
                            <Link to="/autonegociacao" className="btn">{'<'} Voltar</Link>
                            <button className="btn" onClick={() => this.validaCampos()}>Salvar</button>
                        </div>

                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}