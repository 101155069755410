import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Input from '../../Components/Input';

import { Link } from 'react-router-dom';
import ApiService from '../../Utils/services';

export default class ImportBaixas extends Component {

    constructor() {
        super();

        this.pegaArquivo = this.pegaArquivo.bind(this);
        this.state = {
            redirect: false,
            path: '',
            loading: false,
            alert: false,
            alertText: '',
            arquivo: null
        };
    }

    pegaArquivo(arquivo) {
        this.setState({ arquivo: arquivo[0] });
    }

    async realizaUpload() {
        const { arquivo } = this.state;

        this.setState({ loading: true });
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        const formData = new FormData();
        formData.append('arquivo', arquivo, arquivo.name);
        await ApiService.customRequest('empresa/debito/baixar', 'POST', formData, userData.token, 'multipart/form-data')
            .then(res => {
                this.setState({ path: '/debitos', loading: false, redirect: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: '' });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: '' });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    render() {
        const { redirect, path, loading, alert, alertText, arquivo } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} path={path} />
                <Loading show={loading} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <SideMenu menuAtivo={3} />

                <div className="body">
                    <Header />

                    <div className="debitos-import">
                        <h1>Baixa de débitos</h1>
                        <p className="descricao">Envie seu arquivo selecionando no campo abaixo.</p>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Arquivo de importação</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multiInput">
                                    <Input type="file" label="Selecione o arquivo" style={{ display: 'none' }} 
                                        accept=".xlsx, .xls" pegaArquivo={this.pegaArquivo} />
                                </div>
                                <a href="/downloads/modelo_importa_baixa.xlsx" className="baixaModelo" download={true}>Baixe aqui o arquivo modelo padrão</a>
                            </div>
                        </div>
                        <div className="acoes">
                            <Link to="/debitos" className="btn">{'<'} Voltar</Link>
                            <button className="btn" style={arquivo ? {} : { backgroundColor: '#D4D4D4', color: '#B1B1B1' }}
                                onClick={() => this.realizaUpload()}>Enviar Arquivo</button>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}