import React, { Component, Fragment } from 'react';

import '../../resources/css/generals.scss';
import '../../resources/css/pages.scss';
import '../../resources/css/responsive.scss';

import LogoTchauBoleto from '../../resources/logo-tchau-boleto.png';
import LogoExoss from '../../resources/logo-exoss.svg';
import LogoLicenciador from '../../resources/logo-licenciador.svg';

import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import ApiService from '../../Utils/services';
import Funcoes from '../../Utils/functions';

export default class Login extends Component {

    constructor() {
        super();

        this.state = {
            redirect: false,
            loading: false,
            alert: false,
            alertText: '',
            email: '',
            senha: '',
            emailHelper: '',
            senhaHelper: ''
        };
    }

    validaCampos() {
        const { email, senha } = this.state;
        if (Funcoes.validaEmail(email) !== true) {
            this.setState({ emailHelper: 'O e-mail digitado não é valido!' })
        } else {
            this.setState({ emailHelper: '' });
        }
        if (senha.length < 5) {
            this.setState({ senhaHelper: 'A quantidade minima de caracteres para a senha é de 5.' })
        } else {
            this.setState({ senhaHelper: '' })
        }

        if (Funcoes.validaEmail(email) === true && senha.length >= 5) {
            this.realizaLogin();
        }
    }

    async realizaLogin() {
        const { email, senha } = this.state;
        this.setState({ loading: true });

        const dataPost = {
            eusu_email: email,
            eusu_senha: senha
        }
        await ApiService.postEmpresa('login', dataPost)
            .then(result => {
                var res = result.data.data.usuario;
                var salvaSession = {
                    token: result.data.data.token,
                    menus: result.data.data.menus,
                    usuario: {
                        id: res.eusu_id,
                        nome: res.eusu_nome,
                        senha: senha,
                        email: res.eusu_email,
                        status: res.eusu_status,
                        nivelPerfil: res.perfil.eperf_master
                    },
                    empresa: {
                        id: res.empresa.emp_id,
                        nomeFantasia: res.empresa.emp_nomefantasia,
                        cnpj: res.empresa.emp_cnpj,
                        arqId: res.empresa.arq_id,
                        liquidaBoleto: res.empresa.emp_liquidaboleto
                    }
                };
                sessionStorage.setItem('userData', JSON.stringify(salvaSession));
                this.setState({ loading: false, redirect: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    handleSubmit(event) {
        if (event.key === "Enter") {
            this.validaCampos();
        }
    }

    render() {
        const { redirect, loading, alert, alertText, email, emailHelper, senha, senhaHelper } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} path="/home" />
                <Loading show={loading} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false })} />
                <div className="login" style={{ backgroundColor: '#FFF' }}>
                    <div className="contentLogin">
                        <div className="logo">
                            <img src={LogoTchauBoleto} alt="Logo Tchau Boleto" />
                        </div>
                        <div className="acessaForm">
                            <h2>Acessa sua conta</h2>
                            <div>
                                <label>E-mail</label>
                                <input type="text" placeholder="Informe o e-mail de acesso" value={email}
                                    onChange={e => this.setState({ email: e.target.value })} />
                                {emailHelper ? (
                                    <p style={{ marginBottom: 23, color: '#EA5353' }}>* {emailHelper}</p>
                                ) : null}
                            </div>
                            <div>
                                <label>Senha</label>
                                <input type="password" placeholder="Informe sua senha" value={senha}
                                    onKeyPress={e => this.handleSubmit(e)}
                                    onChange={e => this.setState({ senha: e.target.value })} />
                                {senhaHelper ? (
                                    <p style={{ marginBottom: 23, color: '#EA5353' }}>* {senhaHelper}</p>
                                ) : null}
                            </div>
                            <button className="btn entrar bg-roxo" onClick={() => this.validaCampos()}>Entrar</button>
                        </div>
                        <div className="divulgacao">
                            <div>
                                <p>Tecnologia e Pagamento:</p>
                                <img src={LogoTchauBoleto} style={{ width: 89.5 }} alt="Icone do paypex" />
                                <img src={LogoExoss} alt="Icone da Exoss" />
                            </div>
                            <div className="licenciador">
                                <p>Licenciador:</p>
                                <img src={LogoLicenciador} alt="Logo do licenciador" />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}