import React, { Component } from 'react';

import master from '../resources/bandeira-master.svg';
import iconLixeira from '../resources/icon-lixeira.svg';
import iconEstrela from '../resources/icon-estrela.svg';
import iconEditar from '../resources/icon-editar.svg';
import iconVisualizar from '../resources/icon-lupa.svg';

export default class Table extends Component {

    render() {
        const { campos, dados, selecionavel, acao } = this.props;

        return (
            <table className="table">
                <thead>
                    <tr>
                        {selecionavel ? (
                            <th style={{ width: '5%' }}></th>
                        ) : null}
                        {campos.map((campo) => {
                            return (
                                <th key={campo.id} style={campo.thStyle}>{campo.titulo}</th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {
                        dados.map((item) => {
                            return (
                                <tr key={item.id}>
                                    {selecionavel ? (
                                        <td><input type="checkbox" className="checkBoxtable" /></td>
                                    ) : null}
                                    {campos.map((campo) => {
                                        if (typeof (item[campo.dado]) === "object" && typeof (item[campo.dado]) !== null) {
                                            return (
                                                <td style={item[campo.dado].tdStyle}>{item[campo.dado].val}</td>
                                            )
                                        } else {
                                            if (campo.dado === "bandeira") {
                                                return (
                                                    <td style={campo.tdStyle}><img src={master} alt="bandeira do cartao" /></td>
                                                )
                                            } else if (campo.dado === "tag") {
                                                //0- Debito, 1- Pgto Parcial, 2- Em dia, 3- Sem Cobrancas, 4- Pago
                                                const arrTags = {
                                                    styles: [
                                                        { backgroundColor: '#EB5757', color: '#FFF', width: 70 },
                                                        { backgroundColor: '#F2994A', color: '#FFF', width: 70 },
                                                        { backgroundColor: '#27AE60', color: '#FFF', width: 70 },
                                                        { backgroundColor: '#C5C5C5', color: '#FFF', width: 70 }
                                                    ],
                                                    nomes: ['Em débito', 'Pg Parcial', 'Pago', 'Sem débitos']
                                                }
                                                return (
                                                    <td className="tag">
                                                        <span style={arrTags.styles[item[campo.dado]]}>{arrTags.nomes[item[campo.dado]]}</span>
                                                    </td>
                                                )
                                            } else if (campo.dado === "tags") {
                                                const val = item[campo.dado];
                                                const splitVal = val.split(' ');
                                                const selectTag = splitVal.length > 1 ? splitVal[1] : splitVal[0];
                                                const arrTags = {
                                                    débito: { backgroundColor: '#EB5757', color: '#FFFFFF' },
                                                    parcial: { backgroundColor: '#F2994A', color: '#FFFFFF' },
                                                    pago: { backgroundColor: '#27AE60', color: '#FFFFFF' },
                                                    débitos: { backgroundColor: '#C5C5C5', color: '#FFFFFF' },
                                                    dia: { backgroundColor: '#1199da', color: '#FFFFFF' }
                                                }
                                                return (
                                                    <td className="tag" style={campo.tdStyle}>
                                                        <span style={arrTags[selectTag.toLowerCase()]}>{val}</span>
                                                    </td>
                                                )
                                            } else if (campo.dado === "acoes") {
                                                return (
                                                    <td className="acoes">
                                                        {item[campo.dado] !== "" && item[campo.dado] !== "visualizar" && item[campo.dado] !== "editar" ? (
                                                            <img src={iconLixeira} alt="Icone de lixeira" onClick={() => acao(item['id'])} />
                                                        ) : null}
                                                        {item[campo.dado] === "favoritar" ? (
                                                            <img src={iconEstrela} alt="Icone de favoritar" style={{ marginLeft: 18 }} />
                                                        ) : null}
                                                        {item[campo.dado] === "editar" ? (
                                                            <img src={iconEditar} alt="editar" onClick={() => acao(item['id'])} title="Editar"
                                                                style={{ cursor: 'pointer' }} />
                                                        ) : null}
                                                        {item[campo.dado] === "visualizar" ? (
                                                            <img src={iconVisualizar} alt="Visualizar" onClick={() => acao(item['id'])} title="Visualizar"
                                                                style={{ cursor: 'pointer', width: 20 }} />
                                                        ) : null}
                                                    </td>
                                                )
                                            } else {
                                                return (
                                                    <td style={campo.tdStyle}>{item[campo.dado]}</td>
                                                )
                                            }
                                        }
                                    })}
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        );
    }

}