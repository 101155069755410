import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import Funcoes from '../../Utils/functions';
import ApiService from '../../Utils/services';

export default class HistoryNegociation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            table: {
                campos: [
                    { titulo: 'Usuário', dado: 'usuario' },
                    { titulo: 'Data Alteração', dado: 'dtAlteracao', thStyle: { textAlign: 'center' }, tdStyle: { textAlign: 'center' } },
                    { titulo: 'Data Vencimento', dado: 'dtVencimento', thStyle: { textAlign: 'center' }, tdStyle: { textAlign: 'center' } },
                    { titulo: 'Limite Pagto', dado: 'limitePagto', thStyle: { textAlign: 'center' }, tdStyle: { textAlign: 'center' } },
                    { titulo: 'Valor', dado: 'valor', thStyle: { textAlign: 'right' }, tdStyle: { textAlign: 'right' } },
                    { titulo: 'Grupo de Cobrança', dado: 'grupoCobranca', thStyle: { textAlign: 'center' }, tdStyle: { textAlign: 'center' } },
                    { titulo: 'Linha Digitável', dado: 'linhaDigitavel', thStyle: { textAlign: 'center' }, tdStyle: { textAlign: 'center' } }
                ],
                dados: []
            },
            codRef: ''
        };
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.listaNegociacoes(this.props.location.state.cobranca);
        } else {
            this.setState({ path: '/negociacao', redirect: true });
        }
    }

    async listaNegociacoes(cobranca) {
        const { table } = this.state;
        this.setState({ loading: true });
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        await ApiService.getEmpresa('cobranca/' + cobranca.id, userData.token)
            .then(result => {
                const res = result.data.data;
                var historicos = [];
                for (var i = 0; i < res.historico.length; i++) {
                    const item = res.historico[i];
                    const arrGrupo = ['', 'Taxa Empresa', 'Taxa Cliente', 'Taxa Híbrida'];
                    historicos.push({
                        id: item.ecobh_id, usuario: item.usuario ? item.usuario.eusu_nome : '', dtAlteracao: Funcoes.data2Brazilian(item.ecobh_dthralteracao.split(' ')[0], '-'),
                        dtVencimento: Funcoes.data2Brazilian(item.ecobh_dtvencto, '-'), limitePagto: item.ecobh_limiterecebimento ? item.ecobh_limiterecebimento + " dias" : "-",
                        valor: 'R$ ' + Funcoes.float2Preco(item.ecobh_valor), grupoCobranca: arrGrupo[item.ecobh_tipocobranca],
                        linhaDigitavel: item.ecobh_linhadigitavel ? item.ecobh_linhadigitavel.substring(item.ecobh_linhadigitavel.length, item.ecobh_linhadigitavel.length - 6) : "(vazia)"
                    })
                }
                this.setState({ codRef: res.ecob_codreferencia, table: { campos: [...table.campos], dados: historicos }, loading: false });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }

                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    render() {
        const { redirect, path, propsToPath, loading, alert, alertText, table, codRef } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} path={path} props={propsToPath} />
                <Loading show={loading} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <SideMenu menuAtivo={5} />

                <div className="body">
                    <Header />

                    <div className="negociacoes">
                        <div className="tituloButton">
                            <div>
                                <h1>Histórico da Cobrança ({codRef})</h1>
                                <p className="descricao">Abaixo listamos todo histórico de alterações da cobrança.</p>
                            </div>
                        </div>

                        <div className="panel">
                            {table.dados.length > 0 ? (<>
                                <Table campos={table.campos}
                                    dados={table.dados} />
                            </>) : <p style={{ color: '#ABABAB', fontSize: 20 }}>Você ainda não tem negociações!</p>}
                        </div>

                        <div className="acoes action">
                            <a onClick={() => this.setState({
                                path: '/negociacao/incluir', propsToPath: { cobranca: this.props.location.state.cobranca }, redirect: true
                            })} className="btn" style={{ cursor: 'pointer' }}>{'<'} Voltar</a>
                        </div>

                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}