import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Input from '../../Components/Input';

import { Link } from 'react-router-dom';
import ApiService from '../../Utils/services';

export default class Import extends Component {

    constructor() {
        super();

        this.pegaArquivo = this.pegaArquivo.bind(this);
        this.state = {
            redirect: false,
            path: '',
            loading: false,
            alert: false,
            alertText: '',
            grupoCobranca: [
                { value: '', text: 'Selecione' },
                { value: 1, text: 'Taxa Empresa' },
                { value: 2, text: 'Taxa Cliente' },
                { value: 3, text: 'Taxa Hibrído' }
            ],
            parcelasSJuros: [
                { value: '', text: 'Selecione' },
                { value: 1, text: '1' },
                { value: 2, text: '2' },
                { value: 3, text: '3' },
                { value: 4, text: '4' },
                { value: 5, text: '5' },
                { value: 6, text: '6' },
                { value: 7, text: '7' },
                { value: 8, text: '8' },
                { value: 9, text: '9' },
                { value: 10, text: '10' },
                { value: 11, text: '11' },
                { value: 12, text: '12' }
            ],
            isHibrid: false,
            arquivo: null,
            grupos: '',
            parcelas: ''
        };
    }

    pegaArquivo(arquivo) {
        this.setState({ arquivo: arquivo[0] });
    }

    async realizaUpload() {
        const { arquivo, grupos, parcelas } = this.state;

        this.setState({ loading: true });
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        const formData = new FormData();
        formData.append('arquivo', arquivo, arquivo.name);
        formData.append('ecob_tipocobranca', grupos);
        formData.append('ecob_limiteparcelas', parcelas);
        await ApiService.customRequest('empresa/debito/importar', 'POST', formData, userData.token, 'multipart/form-data')
            .then(res => {
                this.setState({ path: '/debitos', loading: false, redirect: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    render() {
        const { redirect, path, loading, alert, alertText, grupoCobranca, parcelasSJuros, isHibrid, arquivo, grupos } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} path={path} />
                <Loading show={loading} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <SideMenu menuAtivo={3} />

                <div className="body">
                    <Header />

                    <div className="debitos-import">
                        <h1>Inclusão de débitos</h1>
                        <p className="descricao">Envie seu arquivo selecionando no campo abaixo.</p>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Arquivo de importação</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multiInput">
                                    <Input type="file" label="Selecione o arquivo" style={{ width: 400, display: 'none' }} grow={1}
                                        accept=".xlsx, .xls" pegaArquivo={this.pegaArquivo} />
                                    <Input type="select" label="Grupo de Cobrança" style={{ marginRight: 45 }} optionsData={grupoCobranca} grow={1}
                                        onChange={e => this.setState({ isHibrid: e.target.value === '3' ? true : false, grupos: e.target.value })} />
                                    {isHibrid ? (
                                        <Input type="select" label="Parcelas S/Juros" optionsData={parcelasSJuros} grow={1}
                                            onChange={e => this.setState({ parcelas: e.target.value })} />
                                    ) : null}
                                </div>
                                <a href="/downloads/modelo_importacao_cobranca.xlsx" className="baixaModelo" download={true}>Baixe aqui o arquivo modelo padrão</a>
                            </div>
                        </div>
                        <div className="acoes">
                            <Link to="/debitos" className="btn">{'<'} Voltar</Link>
                            <button className="btn" style={arquivo && grupos ? {} : { backgroundColor: '#D4D4D4', color: '#B1B1B1' }}
                                onClick={() => this.realizaUpload()}>Enviar Arquivo</button>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}