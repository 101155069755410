import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { PrivateRoute } from './Utils/PrivateRoute';
import './index.css';

import Login from './Pages/Login/Login';
import Dashboard from './Pages/Dashboard/Dashboard';
import Client from './Pages/Clients/Client';
import Debit from './Pages/Debits/Debit';
import Import from './Pages/Debits/Import';
import ImportBaixas from './Pages/Debits/ImportBaixas';
import Payment from './Pages/Payments/Payment';
import PaymentImport from './Pages/Payments/PaymentImport';
import Negociation from './Pages/Negociation/Negociation';
import NewNegociation from './Pages/Negociation/NewNegociation';
import HistoryNegociation from './Pages/Negociation/HistoryNegociation';
import TemplateConfig from './Pages/Messaging/TemplateConfig';
import NewTemplate from './Pages/Messaging/NewTemplate';
import ProgrammedMessages from './Pages/Messaging/ProgrammedMessages';
import NewMessage from './Pages/Messaging/NewMessage';
import Config from './Pages/Config/Config';
import ProfileAccess from './Pages/Config/ProfileAccess';
import NewProfileAccess from './Pages/Config/NewProfileAccess';
import Users from './Pages/Config/Users';
import NewUser from './Pages/Config/NewUser';
import ChangePassword from './Pages/Password/ChangePassword';
import AutomaticSettlement from './Pages/Settlement/AutomaticSettlement';
import CreditoHistorico from './Pages/Credit/CreditoHistorico';
import AutoNegotiation from './Pages/AutoNegotiation/AutoNegotiation';
import NewAutoNegotiation from './Pages/AutoNegotiation/NewAutoNegotiation';
import NotFound from './Pages/NotFound/NotFound';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" exact={true} component={Login} />
      <PrivateRoute path="/home" component={Dashboard} />
      <PrivateRoute path="/clientes" component={Client} />
      <PrivateRoute path="/debitos" exact={true} component={Debit} />
      <PrivateRoute path="/debitos/importar" exact={true} component={Import} />
      <PrivateRoute path="/debitos/importar-baixas" exact={true} component={ImportBaixas} />
      <PrivateRoute path="/negociacao" exact={true} component={Negociation} />
      <PrivateRoute path="/negociacao/incluir" exact={true} component={NewNegociation} />
      <PrivateRoute path="/negociacao/historico" exact={true} component={HistoryNegociation} />
      <PrivateRoute path="/mensageria" exact={true} component={TemplateConfig} />
      <PrivateRoute path="/mensageria/configuracao-template" exact={true} component={TemplateConfig} />
      <PrivateRoute path="/mensageria/novo-template" exact={true} component={NewTemplate} />
      <PrivateRoute path="/mensageria/editar-template" exact={true} component={NewTemplate} />
      <PrivateRoute path="/mensageria/programacao-mensagens" exact={true} component={ProgrammedMessages} />
      <PrivateRoute path="/mensageria/nova-mensagem" exact={true} component={NewMessage} />
      <PrivateRoute path="/mensageria/editar-mensagem" exact={true} component={NewMessage} />
      <PrivateRoute path="/pagamentos" exact={true} component={Payment} />
      <PrivateRoute path="/pagamentos/importar" exact={true} component={PaymentImport} />
      <PrivateRoute path="/configuracoes" component={Config} />
      <PrivateRoute path="/perfil-acesso" exact={true} component={ProfileAccess} />
      <PrivateRoute path="/credito/historico" exact={true} component={CreditoHistorico} />
      <PrivateRoute path="/perfil-acesso/incluir" exact={true} component={NewProfileAccess} />
      <PrivateRoute path="/usuarios" exact={true} component={Users} />
      <PrivateRoute path="/usuarios/incluir" exact={true} component={NewUser} />
      <PrivateRoute path="/alterar-senha" component={ChangePassword} />
      <PrivateRoute path="/liquidacao-automatica" component={AutomaticSettlement} />
      <PrivateRoute path="/autonegociacao" exact={true} component={AutoNegotiation} />
      <PrivateRoute path="/autonegociacao/novo" exact={true} component={NewAutoNegotiation} />
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root'));