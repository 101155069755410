import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import iconAviso from '../../resources/icon-aviso.svg';
import iconEnviar from '../../resources/icon-enviar.svg';
import imgSucesso from '../../resources/icon-sucesso.png';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Input from '../../Components/Input';
import Modal from '../../Components/Modal';
import { dataBrMask, cpfMask, cnpjMask, celularMask } from '../../Utils/mask';
import { validaForm } from '../../Utils/FormValidator';
import Funcoes from '../../Utils/functions';
import ApiService from '../../Utils/services';

export default class NewNegociation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            alert: false,
            alertText: '',
            alertType: 2,
            grupoCobrancaOptions: [
                { value: '', text: 'Selecione' },
                { value: 1, text: 'Taxa Empresa' },
                { value: 2, text: 'Taxa Cliente' },
                { value: 3, text: 'Taxa Híbrida' }
            ],
            parcelasOptions: [
                { value: '', text: 'Selecione' },
                { value: 1, text: '1x' },
                { value: 2, text: '2x' },
                { value: 3, text: '3x' },
                { value: 4, text: '4x' },
                { value: 5, text: '5x' },
                { value: 6, text: '6x' },
                { value: 7, text: '7x' },
                { value: 8, text: '8x' },
                { value: 9, text: '9x' },
                { value: 10, text: '10x' },
                { value: 11, text: '11x' }
            ],
            nomeCliente: '',
            cpfCnpj: '',
            dtNascimento: '',
            nomeMae: '',
            email: '',
            celular: '',
            codRef: '',
            dtVencimento: '',
            limitePagto: '',
            valor: '',
            grupoCobranca: '',
            parcelas: 12,
            linkPagamento: '',
            linhaDigitavel: '',
            readOnly: props.location.state ? true : false,
            modal: false,
            isSending: false,
            modalSucess: false,
            linhasPermitidas: ['033998296995200', '237933870690024', '237933870690027']
        };
    }

    componentDidMount() {
        if (this.props.location.state) this.listaNegociacoes();
    }

    async listaNegociacoes() {
        const { cobranca } = this.props.location.state;
        this.setState({ loading: true });
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        await ApiService.getEmpresa('cobranca/' + cobranca.id, userData.token)
            .then(result => {
                const res = result.data.data;
                this.setState({
                    nomeCliente: res.ecob_nomerazao, cpfCnpj: res.ecob_cpfcnpj, codRef: res.ecob_codreferencia, dtVencimento: Funcoes.data2Brazilian(res.ecob_dtvencto, '-'),
                    limitePagto: res.ecob_limiterecebimento ? res.ecob_limiterecebimento : '', valor: res.ecob_valor ? Funcoes.float2Preco(res.ecob_valor) : '',
                    grupoCobranca: res.ecob_tipocobranca, linkPagamento: res.ecob_linkpagamento, linhaDigitavel: res.ecob_linhadigitavel, email: res.ecob_email,
                    celular: res.ecob_celular, loading: false
                });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }

                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    handleInputs(name, val, mask) {
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        if (mask === "date") {
            this.setState({ [name]: dataBrMask(val.target.value) });
        } else if (mask === "cpf") {
            this.setState({ [name]: cpfMask(val.target.value) });
        } else if (mask === "cnpj") {
            this.setState({ [name]: cnpjMask(val.target.value) });
        } else if (mask === "phone") {
            this.setState({ [name]: celularMask(val.target.value) });
        } else {
            if(name == 'linhaDigitavel' && userData.empresa.id == 12){

                const { linhasPermitidas } = this.state;
                var linhadigitada = val.target.value.replace(".", "").replace(" ", "").substring(0,15);
                console.log(linhadigitada);
                var continua = true;
                if(linhadigitada.length >= 15){
                    if(linhadigitada != linhasPermitidas[0] && linhadigitada != linhasPermitidas[1]){
                        continua = false;
                    }
                }
                if(continua == false){
                    this.setState({ alertText: 'A linha digitável utilizada não é permitida', alert: true, linhaDigitavel: '' });
                }else{
                    this.setState({ linhaDigitavel: val.target.value });
                }
            }else{
                this.setState({ [name]: val.target.value });
            }
        }
    }

    async montaNegociacao() {
        const { nomeCliente, cpfCnpj, codRef, dtVencimento, limitePagto, valor, grupoCobranca, parcelas, linhaDigitavel } = this.state;
        const form = [
            { campo: 'Nome do Cliente', attr: 'nomeCliente', valor: nomeCliente },
            { campo: 'CPF/CNPJ', attr: 'cpfCnpj', valor: cpfCnpj, minLength: 14, tipo: 'cpf' },
            { campo: 'Cód. Referência', attr: 'codRef', valor: codRef },
            { campo: 'Data de Vencimento', attr: 'dtVencimento', valor: dtVencimento },
            { campo: 'Valor', attr: 'valor', valor: valor },
            { campo: 'Grupo de Cobrança', attr: 'grupoCobranca', valor: grupoCobranca }
        ];
        const valida = validaForm(form);
        if (valida.valido) {
            const formData = {
                "ecob_nomerazao": nomeCliente,
                "ecob_cpfcnpj": cpfCnpj,
                "ecob_dtvencto": Funcoes.data2Americano(dtVencimento, '/'),
                "ecob_valor": Funcoes.preco2float(valor ? valor : 0),
                "ecob_codreferencia": codRef,
                "ecob_limiterecebimento": limitePagto,
                "ecob_tipocobranca": grupoCobranca,
                "ecob_limiteparcelas": parcelas,
                "ecob_linhadigitavel": linhaDigitavel
            };
            if (this.props.location.state) {
                this.editarCobranca(formData);
            } else {
                this.adicionarCobranca(formData);
            }
        } else {
            for (var i = 0; i < valida.campos.length; i++) {
                const item = valida.campos[i];
                if (!item.valido) {
                    this.setState({ alertText: item.mensagem, alert: true });
                    break;
                }
            }
        }
    }

    async adicionarCobranca(form) {
        this.setState({ loading: true });
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        await ApiService.postEmpresa('cobranca', form, userData.token)
            .then(res => {
                this.setState({ path: '/negociacao', alertType: 1, alertText: 'Cobrança salva com sucesso!', loading: false, alert: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }

                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    async editarCobranca(form) {
        const { cobranca } = this.props.location.state;
        this.setState({ loading: true });
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        await ApiService.putEmpresa('cobranca/' + cobranca.id, form, userData.token)
            .then(res => {
                this.setState({ alertType: 1, alertText: 'Cobrança alterada com sucesso!', alert: true });
                this.listaNegociacoes();
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }

                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    copiarCodigo = () => {
        const input = document.getElementById("linkPagamento");
        input.select();
        input.setSelectionRange(0, 99999);
        document.execCommand("copy");
    }

    validaLinks = (tipoEnvia) => {
        const { email, celular } = this.state;
        if (tipoEnvia === 1) {
            if (celular) {
                this.enviaLink(tipoEnvia);
            } else {
                this.setState({ alertText: 'Você não possuí um celular cadastrado!', alertType: 2, alert: true });
            }
        }
        if (tipoEnvia === 3) {
            if (email) {
                this.enviaLink(tipoEnvia);
            } else {
                this.setState({ alertText: 'Você não possuí um e-mail cadastrado!', alertType: 2, alert: true });
            }
        }
    }

    enviaLink = async (tipoEnvia) => {

        this.setState({ isSending: true });
        const { cobranca } = this.props.location.state;
        const userData = JSON.parse(sessionStorage.getItem('userData'));

        await ApiService.postEmpresa('cobranca/mensageria/enviar/' + cobranca.id, { tipo: tipoEnvia }, userData.token)
            .then(result => {
                this.setState({ isSending: false, modalSucess: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }

                this.setState({ alertText: valida.response, isSending: false, alert: true });
            })
    }

    render() {
        const { redirect, path, propsToPath, loading, alert, alertText, alertType, nomeCliente, cpfCnpj, codRef, dtVencimento, limitePagto, valor, grupoCobrancaOptions, parcelasOptions,
            grupoCobranca, parcelas, linkPagamento, linhaDigitavel, readOnly, modal, isSending, modalSucess } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} path={path} props={propsToPath} />
                <Loading show={loading} />
                <Modal show={modal} largura={380}>
                    <div className="modalLink">
                        {!isSending && !modalSucess ? (<>
                            <p>Atenção</p>
                            <img src={iconAviso} alt="Icone de aviso" />
                            <h4>Selecione qual o canal de envio do link de pagamento:</h4>
                            <button className="btn" onClick={() => this.validaLinks(1)}>SMS</button>
                            <button className="btn" onClick={() => this.validaLinks(3)}>E-mail</button>
                            {/* <button className="btn" onClick={() => this.enviaLink(2)}>WhatsApp</button> */}
                            <button className="btnCancelar" onClick={() => this.setState({ modal: false })}>cancelar</button>
                        </>) : modalSucess ? (<>
                            <p>Sucesso</p>
                            <img src={imgSucesso} alt="Icone de sucesso" />
                            <h4>O link de pagamento foi enviado com sucesso!</h4>
                            <button className="btnFechar" onClick={() => this.setState({ modal: false, modalSucess: false })}>Fechar</button>
                        </>) : (<>
                            <img src={iconEnviar} alt="Icone de enviando" style={{ marginTop: 68 }} />
                            <h4 style={{ marginTop: 32 }}>Enviando...</h4>
                            <button className="btnFechar" onClick={() => this.setState({ modal: false })}>Fechar</button>
                        </>)}
                    </div>
                </Modal>
                <Alert show={alert} texto={alertText} tipo={alertType} action={() => {
                    this.setState({ alert: false, redirect: path ? true : false });
                }} />
                <SideMenu menuAtivo={5} />

                <div className="body">
                    <Header />

                    <div className="negociacoes">
                        <div className="tituloButton">
                            <div>
                                <h1>Cobrança</h1>
                                <p className="descricao">Abaixo os dados da cobrança</p>
                            </div>
                            {this.props.location.state ? (<>
                                <button className="btn bd-roxo cl-roxo" style={{ width: 203 }} onClick={() => this.setState({ modal: true })}>Enviar Link de Pagto</button>
                                <button className="btn bd-roxo cl-roxo" style={{ width: 203, marginLeft: 30 }}
                                    onClick={() => this.setState({
                                        path: '/negociacao/historico', propsToPath: { cobranca: this.props.location.state.cobranca }, redirect: true
                                    })}>Histórico da Cobrança</button>
                            </>) : null}
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados do Cliente</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multiInput">
                                    <Input type="text" label="Nome do Cliente *" value={nomeCliente} onChange={e => this.handleInputs('nomeCliente', e)} grow={4} readOnly={readOnly} />
                                    <Input type="tel" label="CPF/CNPJ *" value={cpfCnpj} onChange={e => this.handleInputs('cpfCnpj', e, e.target.value.length <= 14 ? 'cpf' : 'cnpj')}
                                        maxLength={18} readOnly={readOnly} style={{ marginRight: 0 }} />
                                    {/* <Input type="tel" label="Data Nascimento/Abertura" placeholder="00/00/0000" style={{ marginRight: 0 }}
                                        value={dtNascimento} onChange={e => this.handleInputs('dtNascimento', e, 'date')} maxLength={10} /> */}
                                </div>
                                {/* <div className="multiInput">
                                    <Input type="text" label="Nome da Mãe" value={nomeMae} onChange={e => this.handleInputs('nomeMae', e)} />
                                    <Input type="email" label="E-mail" value={email} onChange={e => this.handleInputs('email', e)} />
                                    <Input type="tel" label="Celular" placeholder="(00) 00000-0000" style={{ marginRight: 0 }} maxLength={15}
                                        value={celular} onChange={e => this.handleInputs('celular', e, 'phone')} />
                                </div> */}
                            </div>
                        </div>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Dados da Cobrança</h3>
                            </div>
                            <div className="panelBody">
                                <div className="multiInput cinco">
                                    <Input type="text" label="Cód. Referência *" value={codRef} onChange={e => this.handleInputs('codRef', e)} readOnly={readOnly} />
                                    <Input type="tel" label="Data de Vencimento *" placeholder="00/00/0000" maxLength={10}
                                        value={dtVencimento} onChange={e => this.handleInputs('dtVencimento', e, 'date')} />
                                    <Input type="tel" label="Limite Pagto (em dias)" placeholder="00" maxLength={2}
                                        value={limitePagto} onChange={e => this.handleInputs('limitePagto', e)} />
                                    <Input type="money" label="Valor (R$) *" value={valor} onChangeEvent={e => this.handleInputs('valor', e)} />
                                    <Input type="select" label="Grupo de Cobrança *" optionsData={grupoCobrancaOptions} value={grupoCobranca}
                                        onChange={e => this.handleInputs('grupoCobranca', e)} />
                                    {grupoCobranca === '3' ?
                                        <Input type="select" label="Parcelas (sem juros) *" optionsData={parcelasOptions} value={parcelas} style={{ minWidth: 100, marginRight: 0 }}
                                            onChange={e => this.handleInputs('parcelas', e)} />
                                        : null}
                                </div>
                                {linkPagamento ?
                                    <div className="multiInput">
                                        <Input type="text" label="Link de Pagamento" id="linkPagamento" value={linkPagamento} readOnly={true} />
                                        <button onClick={() => this.copiarCodigo()}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M22 6V22H6V6H22ZM24 4H4V24H24V4ZM0 21V0H21V2H2V21H0Z" fill="#777777" />
                                            </svg>
                                        </button>
                                    </div>
                                    : null}
                                <div className="multiInput">
                                    <Input type="text" label="Linha digitável" style={{ marginRight: 0 }}
                                        value={linhaDigitavel} onChange={e => this.handleInputs('linhaDigitavel', e)} />
                                </div>
                            </div>
                        </div>

                        <div className="acoes action">
                            <Link to="/negociacao" className="btn">{'<'} Voltar</Link>
                            <button className="btn" style={nomeCliente && cpfCnpj.length >= 14 && codRef && dtVencimento && valor ? {} :
                                { backgroundColor: '#D4D4D4', color: '#B1B1B1' }}
                                onClick={() => this.montaNegociacao()}>{this.props.location.state ? 'Alterar' : 'Gravar'}</button>
                        </div>

                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}