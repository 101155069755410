import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import ApiService from '../../Utils/services';

export default class ProfileAccess extends Component {

    constructor() {
        super();

        this.acao = this.acao.bind(this);
        this.state = {
            redirect: false,
            path: '',
            propsToPath: null,
            loading: false,
            alert: false,
            alertMessage: '',
            table: {
                campos: [
                    { titulo: 'Nome', dado: 'nome' },
                    { titulo: '', dado: 'acoes', thStyle: { width: 100 } }
                ],
                dados: []
            },
        };
    }

    componentDidMount() {
        this.listaPerfis();
    }

    async listaPerfis() {
        const { table } = this.state;
        this.setState({ loading: true });
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        await ApiService.getEmpresa('perfisacesso', userData.token)
            .then(result => {
                const res = result.data.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    objects.push({ id: res[i].eperf_id, nome: res[i].eperf_nome, acoes: userData.usuario.nivelPerfil === 1 ? 'editar' : res[i].eperf_master === 1 ? '' : 'editar' });
                }
                this.setState({ table: { campos: [...table.campos], dados: objects }, loading: false })
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }

                this.setState({ loading: false, alertMessage: valida.response, alert: true });
            })
    }

    acao(id) {
        this.setState({ path: '/perfil-acesso/incluir', propsToPath: { perfil: { id } }, redirect: true })
    }

    render() {
        const { redirect, path, propsToPath, loading, alert, alertMessage, table } = this.state;
        return (
            <Fragment>
                <Alert show={alert} texto={alertMessage} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <Redirect redirect={redirect} path={path} props={propsToPath} />
                <SideMenu menuAtivo={6} />

                <div className="body">
                    <Header />

                    <div className="configuracao">
                        <div className="tituloButton">
                            <div>
                                <h1>Perfil de Acesso</h1>
                                <p className="descricao">Abaixo listamos os perfis de acesso dos usuários.</p>
                            </div>
                            <button className="btn bd-roxo cl-roxo" style={{ width: 175 }}
                                onClick={() => this.setState({ path: '/perfil-acesso/incluir', redirect: true })}>Incluir perfil</button>
                        </div>

                        <div className="panel">
                            <div className="panelBody">
                                <Table campos={table.campos}
                                    dados={table.dados}
                                    acao={this.acao} />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}