import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Input from '../../Components/Input';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import ApiService from '../../Utils/services';

export default class Config extends Component {

    constructor() {
        super();

        this.pegaArquivo = this.pegaArquivo.bind(this);
        this.state = {
            redirect: false,
            path: '',
            loading: false,
            alert: false,
            alertMessage: '',
            arquivo: null,
            titulo: '',
            econfId: null
        };
    }

    componentDidMount() {
        this.carregaConfigs();
    }

    async carregaConfigs() {
        this.setState({ loading: true });
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        await ApiService.getEmpresa('configuracao/' + userData.empresa.id, userData.token)
            .then(res => {
                const item = res.data.data;
                this.setState({ titulo: item.ecfg_titulopagina, econfId: item.ecfg_id, loading: false });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertText: valida.response, alert: true });
            })
    }

    pegaArquivo(arquivo) {
        this.setState({ arquivo: arquivo[0] });
    }

    async alterarEmpresa() {
        const { arquivo, titulo, econfId } = this.state;
        if (arquivo || titulo) {
            this.setState({ loading: true });
            const userData = JSON.parse(sessionStorage.getItem('userData'));

            const formData = new FormData();
            if (arquivo) formData.append('arquivo_login', arquivo, arquivo.name);
            if (titulo) formData.append('ecfg_titulopagina', titulo);

            await ApiService.customRequest('empresa/configuracao/' + econfId, 'POST', formData, userData.token, 'multipart/form-data')
                .then(res => {
                    this.setState({ loading: false });
                })
                .catch(err => {
                    const valida = ApiService.handleErros(err.response);
                    if (valida.status === "unauthorized") {
                        sessionStorage.removeItem('userData');
                        this.setState({ path: '/' });
                    }
                    if (valida.status === "serverError") {
                        this.setState({ path: null });
                    }

                    if (valida.status === "badRequest") {
                        this.setState({ path: null });
                    }
                    this.setState({ loading: false, alertText: valida.response, alert: true });
                })
        }
    }

    render() {
        const { redirect, path, loading, alert, alertMessage, titulo } = this.state;
        return (
            <Fragment>
                <Alert show={alert} texto={alertMessage} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <Redirect redirect={redirect} path={path} />
                <SideMenu menuAtivo={6} />

                <div className="body">
                    <Header />

                    <div className="configuracao">
                        <h1>Personalizar</h1>
                        <p className="descricao">Parametrize abaixo a sua plataforma.</p>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Portal do Cliente</h3>
                            </div>
                            <div className="panelBody">
                                <Input type="text" label="Título da Página" style={{ width: 350, padding: 10.5 }} value={titulo}
                                    onChange={e => this.setState({ titulo: e.target.value })} />
                                <div style={{ width: 460, marginTop: 20 }}>
                                    <Input type="file" label="Imagem do Login (960 x 1060px)" style={{ width: 400, display: 'none' }} grow={0}
                                        accept=".png, .jpeg, .jpg" pegaArquivo={this.pegaArquivo} />
                                </div>
                            </div>
                        </div>
                        <div className="info">
                            <button className="btn bg-roxo cl-branco" style={{ backgroundColor: '#06014A', width: 170 }}
                                onClick={() => this.alterarEmpresa()}>Gravar Alterações</button>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}