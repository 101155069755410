import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Input from '../../Components/Input';
import Table from '../../Components/Table';
import Paginacao from '../../Components/Paginacao';
import Funcoes from '../../Utils/functions';
import ApiService from '../../Utils/services';
import { dataBrMask } from '../../Utils/mask';

export default class Payment extends Component {

    constructor() {
        super();

        this.handlePage = this.handlePage.bind(this);
        this.state = {
            redirect: false,
            path: '',
            loading: false,
            alert: false,
            alertText: '',
            filtroOptions: [
                { value: '', text: 'Sselecione' },
                { value: 'documento', text: 'CPF/CNPJ' },
                { value: 'cli', text: 'Cliente' },
                { value: 'parceiro', text: 'Parceiro' },
                { value: 'status', text: 'Status' },
            ],
            table: {
                campos: [
                    { titulo: 'Cliente', dado: 'cli' },
                    { titulo: 'CPF/CNPJ', dado: 'documento' },
                    { titulo: 'Data', dado: 'data', thStyle: { textAlign: 'center' }, tdStyle: { textAlign: 'center' } },
                    { titulo: 'Parceiro', dado: 'parceiro', thStyle: { textAlign: 'center' }, tdStyle: { textAlign: 'center' } },
                    { titulo: 'Valor', dado: 'valor', thStyle: { textAlign: 'right' }, tdStyle: { textAlign: 'right' } },
                    { titulo: 'Status', dado: 'status', thStyle: { textAlign: 'center' }, tdStyle: { textAlign: 'center' } },
                ],
                dados: [
                    {cli: "ALAN", documento: "384.832.338-94", data: "7/04/2021", parceiro: "Jeitto", valor: "R$ 149,90", status:"Aprovado"},
                    {cli: "YURI", documento: "357.849.608-14", data: "7/04/2021", parceiro: "SuperSim", valor: "R$ 149,90", status:"Pago"},
                    {cli: "TIAGO", documento: "349.651.985-96", data: "7/04/2021", parceiro: "Lendico", valor: "R$ 149,90", status:"Pré-Aprovado"},
                    {cli: "JOSE", documento: "349.651.985-96", data: "7/04/2021", parceiro: "Jeitto", valor: "R$ 149,90", status:"Pago"},
                ]
            },
            pagamentosDefault: [],
            dtInicio: '',
            dtFim: '',
            linhaDigitavel: '',
            totalRecebido: 0,
            liquidaBoleto: 0
        };
    }

    componentDidMount() {
       
    }


    exportarListagem() {
        
    }

    handlePage(page) {
        if (page) {
            this.setState({ path: '/pagamentos?page=' + page, redirect: true });
        } else {
            this.setState({ path: '/pagamentos', redirect: true });
        }
        this.listaPagamentos(0, page);
    }

    render() {
        const { redirect, path, loading, alert, alertText, filtroOptions, table, pagamentosDefault, dtInicio, dtFim, linhaDigitavel, totalRecebido, liquidaBoleto } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} path={path} />
                <Loading show={loading} />
                <Alert show={alert} texto={alertText} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <SideMenu menuAtivo={9} />

                <div className="body">
                    <Header />

                    <div className="pagamentos">
                        <div className="tituloButton">
                            <div>
                                <h1>Histórico de Crédito</h1>
                                <p className="descricao">Abaixo listamos todas as solicitação de empréstimo realizada na plataforma.</p>
                            </div>
                            {liquidaBoleto === 1 ? (
                                <button className="btn bd-roxo cl-roxo" style={{ width: 200, marginRight: 20 }}
                                    onClick={() => this.setState({ path: '/pagamentos/importar', redirect: true })}>Importar Linha Digitável</button>
                            ) : null}
                            <button className="btn bd-roxo cl-roxo" style={{ marginLeft: liquidaBoleto === 1 ? 'unset' : 'auto' }}
                                onClick={() => this.exportarListagem()}>Exportar (.xls)</button>
                        </div>

                        <div className="filtros">
                            <div className="panel">
                                <Input type="select" label="Buscar por:" grow={0} optionsData={filtroOptions} value={dtInicio} onChange={e => this.setState({ dtInicio: dataBrMask(e.target.value) })} />
                                <p></p>
                                <Input type="tel" grow={0} value={dtFim} onChange={e => this.setState({ dtFim: dataBrMask(e.target.value) })}
                                    style={{ marginTop: 18, marginRight: 31 }} />
                                <button className="btn bg-roxo cl-branco" onClick={() => this.listaPagamentos(1)}>Filtrar</button>
                            </div>
                        </div>

                        <div className="panel pagto">
                            {table.dados.length > 0 ? (<>
                                <Table campos={table.campos}
                                    dados={table.dados} />
                                
                            </>) : <p style={{ color: '#ABABAB', fontSize: 20 }}>Você ainda não tem pagamentos!</p>}
                        </div>

                        <div className="infoPag">
                           
                            <Paginacao dados={pagamentosDefault}
                                handlePage={this.handlePage} />
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}