import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';

export default class NotFound extends Component {

    constructor() {
        super();

        this.state = {
            redirect: false,
            path: ''
        };
    }

    render() {
        const { redirect, path } = this.state;
        return (
            <Fragment>
                <Redirect redirect={redirect} path={path} />
                <SideMenu />

                <div className="body">
                    <Header />

                    <div className="nao-encontrada" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <h3>Ops, essa página não foi encontrada!</h3>
                    </div>
                    <Footer />
                </div>
            </Fragment>
        )
    }
}