import React, { Component, Fragment } from 'react';

import logo from '../resources/logo-tchau-boleto.png';
import iconSair from '../resources/icon-sair.svg';

import ApiService from '../Utils/services';

import { Link } from 'react-router-dom';

export default class Header extends Component {

    constructor() {
        super();

        this.state = {
            showBar: true,
            openedMenu: false
        }
    }

    async realizaLogout() {
        var userData = sessionStorage.getItem('userData');
        if (userData) {
            let user = JSON.parse(userData);
            await ApiService.postEmpresa('logout', {}, user.token);
            sessionStorage.removeItem('userData');
        }
    }

    render() {
        // const { tipoMenu } = this.props;
        // const { showBar, openedMenu } = this.state;
        return (
            <Fragment>
                <div className="navbar">
                    <div className="barLogo">
                        <Link to="/home">
                            <img src={logo} alt="Logo Tchau Boleto" />
                        </Link>
                    </div>

                    <div className="barLinks">
                        <ul>
                            <li>
                                <Link to="/" onClick={() => this.realizaLogout()}>
                                    Sair <img src={iconSair} alt="Ícone de logout" />
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </Fragment>
        )
    }
}