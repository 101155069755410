export const cpfMask = value => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
}

//00.000.000/0000-00
export const cnpjMask = value => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
}

export const cepMask = value => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{5})(\d)/, '$1-$2')
}

export const onlyNumbersMask = value => {
    return value
        .replace(/\D/g, '')
}

export const dataBrMask = value => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .replace(/(\d{2})(\d)/, '$1/$2')
}

export const dataEnMask = value => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
}

export const horaMask = value => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '$1:$2')
}

export const celularMask = value => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})/, '($1) ')
        .replace(/(\d{5})(\d)/, '$1-$2')
}