import React, { Component, Fragment } from 'react';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Table from '../../Components/Table';
import ApiService from '../../Utils/services';

export default class TemplateConfig extends Component {

    constructor() {
        super();

        this.acao = this.acao.bind(this);
        this.state = {
            redirect: false,
            path: '',
            propsToPath: '',
            loading: false,
            alert: false,
            alertMessage: '',
            table: {
                campos: [
                    { titulo: 'Nome', dado: 'nome' },
                    { titulo: 'Tipo', dado: 'tipo', thStyle: { textAlign: 'center' }, tdStyle: { textAlign: 'center' } },
                    { titulo: 'Status', dado: 'status', thStyle: { textAlign: 'center' }, tdStyle: { textAlign: 'center' } },
                    { titulo: 'Predefinida', dado: 'predefinida', thStyle: { textAlign: 'center' }, tdStyle: { textAlign: 'center' } },
                    { titulo: '', dado: 'acoes' }
                ],
                dados: []
            },
            exibeDados: false,
            mostraVazio: false
        };
    }

    componentDidMount() {
        this.carregaTemplates();
    }

    async carregaTemplates() {
        const { table } = this.state;
        this.setState({ loading: true });
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        await ApiService.getEmpresa('mensageria/template', userData.token)
            .then(result => {
                const res = result.data.data, arrTipo = ['', 'SMS', 'Whatsapp', 'Email'], arrStatus = ['', 'Ativo', 'Inativo', 'Excluído'], arrPredf = ['Genérica', 'Boas Vindas'];
                var objects = [], i;

                for (i = 0; i < res.length; i++) {
                    const item = res[i];
                    objects.push({
                        id: item.msgtemp_id, nome: item.msgtemp_nome, tipo: arrTipo[item.msgtemp_tipo], status: arrStatus[item.msgtemp_status],
                        predefinida: arrPredf[item.msgtemp_predefinida], acoes: 'editar'
                    })
                }
                this.setState({
                    table: { campos: [...table.campos], dados: objects }, exibeDados: res.length > 0 ? true : false,
                    mostraVazio: res.length > 0 ? false : true, loading: false
                });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertMessage: valida.response, alert: true });
            })
    }

    async acao(id) {
        this.setState({ loading: true });
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        await ApiService.getEmpresa('mensageria/template/' + id, userData.token)
            .then(result => {
                const res = result.data;
                this.setState({ propsToPath: { template: res.data }, path: '/mensageria/editar-template', loading: false, redirect: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") {
                    this.setState({ path: null });
                }

                if (valida.status === "badRequest") {
                    this.setState({ path: null });
                }
                this.setState({ loading: false, alertMessage: valida.response, alert: true });
            })
    }

    render() {
        const { redirect, path, propsToPath, loading, alert, alertMessage, table, exibeDados, mostraVazio } = this.state;
        return (
            <Fragment>
                <Alert show={alert} texto={alertMessage} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <Redirect redirect={redirect} path={path} props={propsToPath} />
                <SideMenu menuAtivo={8} />

                <div className="body">
                    <Header />

                    <div className="messaging">
                        <div className="tituloButton">
                            <div>
                                <h1>Config. de Templates</h1>
                                <p className="descricao">Abaixo listamos todos os templates configurados</p>
                            </div>
                            <button className="btn" onClick={() => this.setState({ path: '/mensageria/novo-template', redirect: true })}>Incluir Novo</button>
                        </div>

                        <div className="panel">
                            {exibeDados ? (<>
                                <Table campos={table.campos}
                                    dados={table.dados}
                                    acao={this.acao} />
                            </>) : null}
                            {mostraVazio ? (
                                <p style={{ color: '#ABABAB', fontSize: 20 }}>Você ainda não tem templates configurados!</p>
                            ) : null}
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}