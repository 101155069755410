import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import SideMenu from '../../Components/SideMenu';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import Redirect from '../../Components/RedirectScreen';
import Loading from '../../Components/LoaderScreen';
import Alert from '../../Components/Alert';
import Input from '../../Components/Input';
import ApiService from '../../Utils/services';

export default class NewProfileAccess extends Component {

    constructor(props) {
        super(props);

        this.state = {
            redirect: false,
            path: '',
            loading: false,
            alert: false,
            alertMessage: '',
            alertType: 2,
            nome: '',
            menus: []
        };
    }

    componentDidMount() {
        this.listaMenus();
    }

    async listaMenus() {
        this.setState({ loading: true });
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        await ApiService.getEmpresa('perfilacesso/menus', userData.token)
            .then(result => {
                const res = result.data;
                var objects = [];
                for (var i = 0; i < res.length; i++) {
                    const item = res[i];
                    if (!item.pmnu_pai) {
                        objects.push({ ...item, filhos: [], checado: item.pmnu_padrao ? true : false });
                    } else {
                        for (var x = 0; x < objects.length; x++) {
                            if (objects[x].pmnu_id === item.pmnu_pai) {
                                objects[x]['filhos'].push({ ...item, checado: false });
                            }
                        }
                    }
                }
                this.setState({ menus: objects, loading: this.props.location.state ? true : false });
                if (this.props.location.state) this.exibePerfil();
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }

                this.setState({ loading: false, alertMessage: valida.response, alert: true });
            })
    }

    async exibePerfil() {
        const { menus } = this.state;
        const { perfil } = this.props.location.state;
        const userData = JSON.parse(sessionStorage.getItem('userData'));
        await ApiService.getEmpresa('perfisacesso/' + perfil.id, userData.token)
            .then(result => {
                const res = result.data.data;
                for (var x = 0; x < menus.length; x++) {
                    for (var i = 0; i < res.menus.length; i++) {
                        const item = res.menus[i];
                        if (item.pmnu_id === menus[x].pmnu_id) {
                            menus[x].checado = true;
                            if (menus[x].filhos.length > 0) {
                                for (var y = 0; y < menus[x].filhos.length; y++) {
                                    for (var z = 0; z < item.filhos.length; z++) {
                                        if (item.filhos[z].pmnu_id === menus[x].filhos[y].pmnu_id) {
                                            menus[x].filhos[y].checado = true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                this.setState({ nome: res.eperf_nome, menus: menus, loading: false });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }

                this.setState({ loading: false, alertMessage: valida.response, alert: true });
            })
    }

    validaCampos() {
        const { nome } = this.state;
        const menuForm = document.getElementsByName('menu[]');
        var menus = [];
        for (var i = 0; i < menuForm.length; i++) {
            const item = menuForm[i];
            if (item.checked) menus.push(item.value);
        }
        if (nome && menus.length > 0) {
            this.setState({ loading: true });
            const formData = {
                "eperf_nome": nome,
                "pmnu_id": menus.join(',')
            };
            const userData = JSON.parse(sessionStorage.getItem('userData'));
            if (this.props.location.state) {
                this.editarPerfil(formData, userData.token);
            } else {
                this.incluirPerfil(formData, userData.token);
            }
        } else {
            if (menus.length === 0) this.setState({ alertMessage: 'Escolha pelo menos um menu!', alert: true });
            if (!nome) this.setState({ alertMessage: 'O campo Nome do Perfil não pode ficar vazio!', alert: true });
        }
    }

    async incluirPerfil(form, token) {
        await ApiService.postEmpresa('perfisacesso', form, token)
            .then(res => {
                this.setState({ path: '/perfil-acesso', loading: false, redirect: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }

                this.setState({ loading: false, alertMessage: valida.response, alert: true });
            })
    }

    async editarPerfil(form, token) {
        const { perfil } = this.props.location.state;
        await ApiService.putEmpresa('perfisacesso/' + perfil.id, form, token)
            .then(res => {
                this.setState({ alertMessage: 'Perfil alterado com sucesso', alertType: 1, loading: false, alert: true });
            })
            .catch(err => {
                const valida = ApiService.handleErros(err.response);
                if (valida.status === "unauthorized") {
                    sessionStorage.removeItem('userData');
                    this.setState({ path: '/' });
                }
                if (valida.status === "serverError") { this.setState({ path: '' }); }
                if (valida.status === "badRequest") { this.setState({ path: '' }); }

                this.setState({ loading: false, alertMessage: valida.response, alert: true });
            })
    }

    handleChange(index, indexFilho){
        const { menus } = this.state;
        for(var i = 0; i < menus.length; i++){
            if(indexFilho !== undefined){
                for(var x = 0; x < menus[i].filhos.length; x++){
                    if(i === index && x === indexFilho){
                        menus[i].filhos[x].checado = !menus[i].filhos[x].checado
                    }
                }
            }else{
                if(i === index){
                    menus[i].checado = !menus[i].checado
                }
            }
        }
        this.setState({ menus: menus });
    }

    render() {
        const { redirect, path, loading, alert, alertMessage, alertType, nome, menus } = this.state;
        return (
            <Fragment>
                <Alert show={alert} texto={alertMessage} tipo={alertType} action={() => this.setState({ alert: false, redirect: path ? true : false })} />
                <Loading show={loading} />
                <Redirect redirect={redirect} path={path} />
                <SideMenu menuAtivo={6} />

                <div className="body">
                    <Header />

                    <div className="configuracao">
                        <h1>{this.props.location.state ? 'Editar' : 'Novo'} Perfil de Usuário</h1>
                        <p className="descricao">{this.props.location.state ? 'Editar' : 'Incluir novo'} perfil de acesso dos usuários.</p>

                        <div className="panel">
                            <div className="panelHeader">
                                <h3>Informações</h3>
                            </div>
                            <div className="panelBody menus">
                                <div className="multiInput">
                                    <Input type="text" label="Nome do Perfil" value={nome} onChange={e => this.setState({ nome: e.target.value })} style={{ maxWidth: 400 }} />
                                </div>
                                {menus.map((item, idx) => {
                                    return (
                                        <div className="menu" key={idx}>
                                            <label>
                                                <input type="checkbox" name="menu[]" value={item.pmnu_id} checked={item.checado ? true : false}
                                                    onChange={e => this.handleChange(idx)} />
                                                {item.pmnu_nome}
                                            </label>
                                            {item.filhos.map((child, index) => {
                                                return (
                                                    <div className="menu filho" style={{ marginLeft: 30 }} key={child.pmnu_id}>
                                                        <Input type="checkbox" label={child.pmnu_nome} name="menu[]" value={child.pmnu_id} checked={child.checado ? true : false}
                                                            onChange={e => this.handleChange(idx, index)} />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>

                        <div className="actions">
                            <Link to="/perfil-acesso" className="btn">{'<'} Voltar</Link>
                            <button className="btn bg-roxo cl-branco" onClick={() => this.validaCampos()}>{this.props.location.state ? 'Alterar' : 'Salvar'}</button>
                        </div>

                    </div>
                </div>
                <Footer />
            </Fragment>
        )
    }
}